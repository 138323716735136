import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSmsTemplateClient,
} from "@hooks";
import { CreateSmsTemplateRequest } from "@data";
import { CreateSmsTemplateResponse } from "@network";
import { isEmptyString } from "@util";

type SuccessResponse = CreateSmsTemplateResponse;

export interface UseCreateSmsTemplateProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  templateBody: CreateSmsTemplateRequest;
}

export interface UseCreateSmsTemplateModel extends UseApiRequestModel<SuccessResponse> {
  templateId: string;
}

export interface UseCreateSmsTemplateActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseCreateSmsTemplateProps;
type Model = UseCreateSmsTemplateModel;
type Actions = UseCreateSmsTemplateActions;
type Result = [Model, Actions];

export const useCreateSmsTemplate = (props: Props): Result => {

  const {
    templateBody,
    defaultErrorMessage = "Failed to create SMS template",
    ...otherProps
  } = props;

  const SmsTemplateClient = useSmsTemplateClient();

  const data = templateBody.toJS();

  if (isEmptyString(data.applicationId)) {
    delete data.applicationId;
  }

  const makeApiRequest = React.useCallback(
    () => SmsTemplateClient.createSmsTemplate(JSON.stringify(data)),
    [SmsTemplateClient, data]);

  const [{successResponse, showSuccessView, ...baseModel}, { ...baseActions} ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const templateId = React.useMemo(() => {
    return successResponse ? successResponse.id : "";
  }, [successResponse]);

  const successMessage = React.useMemo(() => showSuccessView ? "SMS template created" : "", [templateId]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
    templateId,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
    templateId,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useCreateSmsTemplate;
