import React from "react";
import { isSmsTemplateEnabled } from "@util";
import { useHistory } from "react-router-dom";
import UserIdentityType from "@data/UserIdentityType";
import { getPathToUser } from "@modules/userDetails/helpers";
import SelectUserIdentityType from "./components/SelectUserIdentityType";
import InviteUser from "./containers/InviteUser";

export function InviteUserView() {

  const history = useHistory();

  const [identityType, setIdentityType] = React.useState<UserIdentityType | null>(
    // If SMS templates aren't enabled, skip the identity type selection screen and default to email
    isSmsTemplateEnabled() ? null : UserIdentityType.EMAIL);

  const showUserDetails = React.useCallback((userId: string) => {
    history.replace(getPathToUser(userId));
  }, [history]);

  if (identityType === null) {
    return (
      <SelectUserIdentityType
        setIdentityType={setIdentityType}
      />
    );
  }

  return (
    <InviteUser
      identityType={identityType}
      showUserDetails={showUserDetails}
    />
  );
}

export default InviteUserView;
