import { isEmptyString } from "@util";
import { createQueryParams, makeJsonApiRequest, withAuthToken } from "./helpers";
import {
  CostReportingGroupedMetricAttributes,
  CostReportingMetricAttributes,
  CostReportingPeriodType
} from "@data";

const DEFAULT_LIMIT = 200;

const PORTAL_ORCHESTRATION_API = process.env.REACT_APP_PORTAL_ORCHESTRATION_API || "";

if (isEmptyString(PORTAL_ORCHESTRATION_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_PORTAL_ORCHESTRATION_API");
}

export interface GetCostReportingMetricsResponse {
  metrics: CostReportingMetricAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetCostReportingGroupedMetricsResponse {
  metrics: CostReportingGroupedMetricAttributes[];
  paging?: {
    next?: string;
  };
}

export interface GetCostReportingMetricsOptions {
  next?: string;
  limit?: number;
  periodType?: CostReportingPeriodType;
}

export interface GetCostReportingGroupedMetricsOptions {
  next?: string;
  limit?: number;
  metricFilter: string;
  resourceType: string;
  periodType: CostReportingPeriodType;
}

export const getCostReportingMetrics = (
  authToken: string,
  options: GetCostReportingMetricsOptions
): Promise<GetCostReportingMetricsResponse> => {

  const { periodType, next, limit = DEFAULT_LIMIT } = options || {};

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({ periodType, next, limit });

    const url = `${PORTAL_ORCHESTRATION_API}/portal/cost-reporting/v1/metrics/list${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch metrics failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getCostReportingGroupedMetrics = (
  authToken: string,
  options: GetCostReportingGroupedMetricsOptions
): Promise<GetCostReportingGroupedMetricsResponse> => {

  const { periodType, metricFilter, resourceType, next, limit = DEFAULT_LIMIT } = options || {};

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({ periodType, metricFilter, resourceType, next, limit });

    const url = `${PORTAL_ORCHESTRATION_API}/portal/cost-reporting/v1/metrics/list/grouped${queryParams}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch grouped metrics failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
