import React from "react";
import classnames from "classnames";
import { formEventHandler, isEmptyString, noop } from "@util";
import TextField, { TextFieldProps } from "@components/text-field";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface UserIdTextFieldModel {
  className?: string;
  label?: string;
  placeholder?: string;
  userId?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
}

export interface UserIdTextFieldActions {
  setUserId?: (updatedValue: string) => void;
}

type Model = UserIdTextFieldModel;
type Actions = UserIdTextFieldActions;
type Props = WithStyles<typeof styles> & Model & Actions & TextFieldProps;

export const UserIdTextField = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    type = "email",
    label = "User ID",
    placeholder = "User email address",
    userId = "",
    value = userId,
    errorMessage = "",
    showErrorMessage = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]),
    error = showErrorMessage,
    helperText: normalHelperText = "",
    setUserId = noop,
    onChange = formEventHandler(setUserId),
    ...otherProps
  } = props;

  const helperText = React.useMemo(() =>
      showErrorMessage ? errorMessage : normalHelperText,
    [showErrorMessage, errorMessage, normalHelperText]);

  const formHelperTextProps = React.useMemo(() => ({
    className: classnames("formHelperText", classes.formHelperText),
    classes: {
      error: classes.formHelperTextError,
    },
  }), [classes]);

  const inputLabelProps = React.useMemo(() => ({
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  }), [classes]);

  const inputProps = React.useMemo(() => ({
    className: classnames("textField", classes.textField),
    spellCheck: false,
  }), [classes]);

  return (
    <TextField
      {...otherProps}
      className={classnames("userIdTextField", className, classes.textField)}
      name="userId"
      variant="outlined"
      autoComplete="off"
      margin="none"
      fullWidth={true}
      autoFocus={true}
      type={type}
      label={label}
      value={value}
      inputProps={inputProps}
      helperText={helperText}
      placeholder={placeholder}
      error={error}
      onChange={onChange}
      FormHelperTextProps={formHelperTextProps}
      InputLabelProps={inputLabelProps}
    />
  );
});

export default UserIdTextField;
