import createStyles from "@material-ui/core/styles/createStyles";
import { Color } from "@components";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    label: {
      color: Color.TEXT,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
    },
    warningContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginBottom: 15,
    },
    information: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      marginTop: 15,
      color: Color.RED,
    },
    warning: {
      fontWeight: 500,
      marginRight: 4,
    },
    error: {
      color: Color.RED,
      textDecoration: "none",
      cursor: "default",
    },
    success: {
      color: Color.DARK_GREEN,
      textDecoration: "none",
      cursor: "default",
    },
    button: {
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 400,
      marginLeft: 16,
      "&:first-child": {
        marginLeft: 0,
      },
      backgroundColor: Color.WHITE,
      borderRadius: 8,
      color: Color.TEXT,
      padding: "0 8px",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      "&:hover": {
        backgroundColor: Color.GREY1,
      },
      "&:active": {
        backgroundColor: Color.GREY2,
      },
    },
    cancelButton: {
      marginLeft: 8,
    },
    loadingIndicator: {
    },
    dialog: {
    },
    dialogLabel: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
    dialogContent: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: 16,
      border: `1px dashed ${Color.TEXT}`,
      borderRadius: 6,
      textAlign: "center",
    },
    dialogContentInput: {
      flex: "0 0 auto",
    },
    dialogContentDragging: {
      backgroundColor: Color.LIGHT_GREY2,
    },
    dialogContentSelected: {
      backgroundColor: Color.LIGHT_GREY,
    },
    successContainer: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
      marginTop: 10,
    },
    checkboxContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      marginTop: 15,
    },
    checkboxMessage: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "18px",
    },
    checkbox: {
    },
  });
