import { InviteUserResponse, UserIdmClient } from "@network";
import { useApiRequestAnalytics } from "@hooks";

export type InviteUserApi = (applicationId: string,
                             json: string) => Promise<InviteUserResponse>;

export interface UserIdmClientApi {
  inviteUser: InviteUserApi;
}

enum ActionTypes {
  INVITE_USER_REQUEST = "user_idm_client/invite_user_request",
  INVITE_USER_SUCCESS = "user_idm_client/invite_user_success",
  INVITE_USER_FAILED = "user_idm_client/invite_user_failed",
}

export const useInviteUserApi = () =>
  useApiRequestAnalytics(UserIdmClient.inviteUser, {
    REQUEST_EVENT: ActionTypes.INVITE_USER_REQUEST,
    SUCCESS_EVENT: ActionTypes.INVITE_USER_SUCCESS,
    ERROR_EVENT: ActionTypes.INVITE_USER_FAILED,
  });

export const useUserIdmClient = (): UserIdmClientApi => ({
  inviteUser: useInviteUserApi(),
});

export { ActionTypes as UserIdmClientActionType };

export default useUserIdmClient;
