import React from "react";
import { ActionMenuItem, createColumns, DeleteSmsTemplateDialog, ListViewItem, ModuleListView } from "@components";
import { useGetSmsTemplates } from "@hooks";
import { PrimaryIcon } from "./styles";
import { SmsTemplate } from "@data";
import { useHistory } from "react-router-dom";
import { getPathToSmsTemplate } from "@modules/smsTemplateDetails/helpers";
import { getCreateSmsTemplatePath, getEditSmsTemplatePath } from "@modules/smsTemplateWizard/helpers";
import { noop } from "@util";

enum SmsTemplateListColumn {
  TYPE = "Type",
  DESCRIPTION = "Description",
}

export enum SmsTemplateAction {
  EDIT_TEMPLATE = "edit_sms_template",
  DELETE_TEMPLATE = "delete_sms_template",
}

export const EDIT_SMS_TEMPLATE: ActionMenuItem = Object.freeze({
  id: SmsTemplateAction.EDIT_TEMPLATE,
  name: "Edit SMS Template",
  disabled: false,
});

export const DELETE_SMS_TEMPLATE: ActionMenuItem = Object.freeze({
  id: SmsTemplateAction.DELETE_TEMPLATE,
  name: "Delete SMS Template",
  disabled: false,
});

export function SmsTemplatesView() {

  const history = useHistory();

  const showSmsTemplateDetails = React.useCallback((template: SmsTemplate) =>
    history.push(getPathToSmsTemplate(template.getId())), [history]);

  const createSmsTemplate = React.useCallback(() =>
    history.push(getCreateSmsTemplatePath()), [history]);

  const editSmsTemplate = React.useCallback((id: string) =>
    history.push(getEditSmsTemplatePath(id)), [history]);

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [templateId, setTemplateId] = React.useState("");

  const deleteSmsTemplate = React.useCallback((id: string) => {
    setTemplateId(id);
    setShowDeleteDialog(true);
  }, [setTemplateId, setShowDeleteDialog]);

  const onClickAction = (template: SmsTemplate, action: ActionMenuItem) => {
    switch (action.id) {
      case SmsTemplateAction.EDIT_TEMPLATE:
        return editSmsTemplate(template.getId());
      case SmsTemplateAction.DELETE_TEMPLATE:
        return deleteSmsTemplate(template.getId());
      default:
        return noop();
    }
  };

  const [{ smsTemplates, ...otherProps }, { refresh }] = useGetSmsTemplates();

  const moduleListItems: ListViewItem<SmsTemplate>[] = React.useMemo(() =>
    smsTemplates.map(template => {
      return {
        item: template,
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "templateType",
            value: template.getMessageType(),
            column: SmsTemplateListColumn.TYPE,
            firstColumn: true,
          }, {
            className: "templateDescription",
            value: template.getDescription(),
            column: SmsTemplateListColumn.DESCRIPTION,
          }
        ]),
      };
    }), [smsTemplates]);

  const onSuccess = React.useCallback(() => {
    setShowDeleteDialog(false);
    refresh();
  }, [setShowDeleteDialog, refresh]);

  return (
    <React.Fragment>
      <ModuleListView
        {...otherProps}
        summaryViewLabel="SMS Templates"
        showSummary={true}
        showCreateButton={true}
        createButtonLabel="Create SMS Template"
        listViewItems={moduleListItems}
        columns={[SmsTemplateListColumn.TYPE, SmsTemplateListColumn.DESCRIPTION]}
        summaryViewIcon={PrimaryIcon}
        noResultsLabel="No SMS templates found"
        summaryViewQuantities={{other: "SMS templates", one: "SMS template"}}
        actions={[EDIT_SMS_TEMPLATE, DELETE_SMS_TEMPLATE]}
        onClickAction={onClickAction}
        refresh={refresh}
        onClickItem={(t) => showSmsTemplateDetails(t)}
        onClickCreateButton={() => createSmsTemplate()}
      />
      <DeleteSmsTemplateDialog
        open={showDeleteDialog}
        templateId={templateId}
        cancel={() => setShowDeleteDialog(false)}
        onSuccessMessageShown={onSuccess}
      />
    </React.Fragment>
  );
}

export default SmsTemplatesView;
