import React from "react";
import { useInviteUser } from "@hooks";
import { isEmptyString, noop } from "@util";
import {
  InviteUserRequest,
  UserIdentityType,
  UserProfileAttribute,
  UserProfileAttributesMap,
} from "@data";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import EditProfileAttributes from "./EditProfileAttributes";
import SelectApplication from "./SelectApplication";
import SelectEmailTemplate from "./SelectEmailTemplate";
import SelectSmsTemplate from "../components/SelectSmsTemplate";
import InviteUserState from "../models/InviteUserState";
import ReviewView from "../components/ReviewView";
import SmsTemplate from "@data/SmsTemplate";

// Regex to ensure a phone number is in a valid format
const VALIDATE_PHONE_REGEX = /^\+[1-9]{1,2}(?:[0-9\-()\/.]\s?){3,14}$/;

export enum NewUserWizardStep {
  NONE = "",
  USER_INFO = "user_info",
  APPLICATION = "application",
  EMAIL_TEMPLATE = "email_template",
  SMS_TEMPLATE = "sms_template",
  REVIEW = "review",
}

export const WIZARD_STEP_LABELS = {
  [NewUserWizardStep.NONE]: null,
  [NewUserWizardStep.USER_INFO]: "Enter User Info",
  [NewUserWizardStep.APPLICATION]: "Select Application",
  [NewUserWizardStep.EMAIL_TEMPLATE]: "Select Email Template (Optional)",
  [NewUserWizardStep.SMS_TEMPLATE]: "Select SMS Template (Optional)",
  [NewUserWizardStep.REVIEW]: "Review Invitation",
};

export const DEFAULT_EMAIL_STEPS = [
  NewUserWizardStep.USER_INFO,
  NewUserWizardStep.APPLICATION,
  NewUserWizardStep.EMAIL_TEMPLATE,
  NewUserWizardStep.REVIEW,
];

export const DEFAULT_SMS_STEPS = [
  NewUserWizardStep.USER_INFO,
  NewUserWizardStep.APPLICATION,
  NewUserWizardStep.SMS_TEMPLATE,
  NewUserWizardStep.REVIEW,
];

interface ContainerModel extends SubmitApiRequestViewModel<InviteUserState, NewUserWizardStep> {
  identityType?: UserIdentityType;
}

interface ContainerActions extends SubmitApiRequestViewActions<InviteUserState, NewUserWizardStep> {
  showUserDetails?: (userId: string) => void;
}

type Props = ContainerModel & ContainerActions & {
  children?: React.ReactNode;
};

const InviteUserContainer = (props: Props) => {

  const {
    className = "inviteUser",
    snackbarId = "invite-user",
    errorTitle = "Invite User Failed",
    saveButtonLabel = "Invite User",
    defaultState = InviteUserState.EMPTY,
    identityType = UserIdentityType.EMAIL,
    steps = React.useMemo(() =>
        identityType === UserIdentityType.EMAIL ? DEFAULT_EMAIL_STEPS : DEFAULT_SMS_STEPS,
      [identityType]),
    mapStepToLabel = (step: NewUserWizardStep) => WIZARD_STEP_LABELS[step] || null,
    showUserDetails = noop,
    children,
    ...otherProps
  } = props;

  const [userId, setUserId] = React.useState<string>("");
  const [applicationId, setApplicationId] = React.useState<string>("");
  const [emailTemplateId, setEmailTemplateId] = React.useState<string>("");
  const [selectedSmsTemplate, setSelectedSmsTemplate] = React.useState<SmsTemplate>(SmsTemplate.EMPTY);
  const [sendEmailCopy, setSendEmailCopy] = React.useState<boolean>(false);
  const [profile, setProfile] = React.useState<UserProfileAttributesMap>({});

  const smsTemplateId = React.useMemo(() =>
    selectedSmsTemplate.getId(), [selectedSmsTemplate]);

  const userIdErrorMessage = React.useMemo(() => {
    // Once the user starts typing something, make sure it is a valid phone number
    if (identityType === UserIdentityType.PHONE && !isEmptyString(userId)) {
      return !VALIDATE_PHONE_REGEX.test(userId) ? "Required Phone Number Format: +1-555-555-5555" : "";
    }
    // Email address validation is too complex to perform client-side, so we will instead rely on
    // backend to validate input and show any error messages sent back.
    return "";
  }, [identityType, userId]);

  const userProfileAttributesMap = React.useMemo(() =>
    Object.keys(profile).reduce((attrs: UserProfileAttributesMap, key: string) => {
      attrs[key] = new UserProfileAttribute(profile[key]);
      return attrs;
    }, {}), [profile]);

  const userProfileAttributes = React.useMemo(() =>
    Object.keys(userProfileAttributesMap).map(key => userProfileAttributesMap[key]),
    [userProfileAttributesMap]);

  const profileValueUpdated = React.useMemo(() =>
    userProfileAttributes.some(attr => !isEmptyString(attr.value)),
    [userProfileAttributes]);

  const inviteUserRequest = React.useMemo(() =>
    InviteUserRequest.from(userProfileAttributesMap, emailTemplateId),
    [userProfileAttributesMap, emailTemplateId]);

  const updateProfile = React.useCallback((obj: UserProfileAttributesMap) => {
    const profileAttributes = Object.keys(obj)
      .reduce((attributes: UserProfileAttributesMap, key: string) => {
        const attribute = obj[key];
        attributes[attribute.getName()] = attribute;
        return attributes;
      }, {});
    return setProfile(profileAttributes);
  }, [setProfile]);

  const updateApplicationId = React.useCallback((appId: string) => {
    setApplicationId(appId);
    // Reset the template id whenever the application id changes to ensure that the
    // user must consciously select an email template appropriate for that application; this
    // logic will prevent a previously selected email template for a different application from
    // being used if the application id is changed
    setEmailTemplateId("");
    return setSelectedSmsTemplate(SmsTemplate.EMPTY);
  }, [setApplicationId, setEmailTemplateId, setSelectedSmsTemplate]);

  const [{ showLoadingIndicator, inviteUserResponse, ...otherModel }, { inviteUser, reset, ...otherActions }] =
    useInviteUser({
      userId,
      applicationId,
      templateId: identityType === UserIdentityType.EMAIL ? emailTemplateId : smsTemplateId,
      sendEmailCopy: sendEmailCopy && identityType === UserIdentityType.EMAIL,
      inviteUserRequest,
    });

  const actualUserId = React.useMemo(() => inviteUserResponse.userId, [inviteUserResponse]);

  const onSuccess = React.useCallback(() =>
    showUserDetails(actualUserId), [showUserDetails, actualUserId]);

  const currentState = React.useMemo(() => new InviteUserState({
    userId,
    identityType,
    applicationId,
    emailTemplateId,
    smsTemplateId,
    profileValueUpdated
  }), [userId, identityType, applicationId, emailTemplateId, smsTemplateId, profileValueUpdated]);

  const editProfileAttributesView = React.useMemo(() => (
    <EditProfileAttributes
      identityType={identityType}
      profile={profile}
      userId={userId}
      userIdErrorMessage={userIdErrorMessage}
      showLoadingIndicator={showLoadingIndicator}
      setUserId={setUserId}
      setProfile={updateProfile}
    />
  ), [identityType, profile, userId, userIdErrorMessage, showLoadingIndicator, setUserId, updateProfile]);

  const selectApplicationView = React.useMemo(() => (
    <SelectApplication applicationId={applicationId} setApplicationId={updateApplicationId} />
  ), [applicationId, updateApplicationId]);

  const selectEmailTemplateView = React.useMemo(() => (
    <SelectEmailTemplate
      emailTemplateId={emailTemplateId}
      setSelectedEmailTemplate={setEmailTemplateId}
    />
  ), [emailTemplateId, setEmailTemplateId]);

  const selectSmsTemplateView = React.useMemo(() => (
    <SelectSmsTemplate
      selectedItems={SmsTemplate.EMPTY.equals(selectedSmsTemplate) ? [] : [selectedSmsTemplate]}
      setSelectedItems={(items: SmsTemplate[]) => setSelectedSmsTemplate(items[0] || SmsTemplate.EMPTY)}
    />
  ), [selectedSmsTemplate, setSelectedSmsTemplate]);

  const reviewView = React.useMemo(() => (
    <ReviewView
      currentState={currentState}
      request={inviteUserRequest}
      sendEmailCopy={sendEmailCopy}
      showSendEmailCopySwitch={identityType === UserIdentityType.EMAIL}
      setSendEmailCopy={setSendEmailCopy}
    />
  ), [identityType, currentState, sendEmailCopy, inviteUserRequest, setSendEmailCopy]);

  const mapStepToView = React.useCallback((step: NewUserWizardStep) => {
    switch (step) {
      case NewUserWizardStep.USER_INFO:
        return editProfileAttributesView;
      case NewUserWizardStep.APPLICATION:
        return selectApplicationView;
      case NewUserWizardStep.EMAIL_TEMPLATE:
        return selectEmailTemplateView;
      case NewUserWizardStep.SMS_TEMPLATE:
        return selectSmsTemplateView;
      case NewUserWizardStep.REVIEW:
        return reviewView;
      default:
        return null;
    }
  }, [
    editProfileAttributesView,
    selectApplicationView,
    selectEmailTemplateView,
    selectSmsTemplateView,
    reviewView,
  ]);

  const validUserId = React.useMemo(() => {
    // Make sure that the user has typed something and that there is no validation error
    return !isEmptyString(userId) && isEmptyString(userIdErrorMessage);
  }, [userId, userIdErrorMessage]);

  const validApplicationId = React.useMemo(() => !isEmptyString(applicationId), [applicationId]);

  const validEmailTemplate = React.useMemo(() => !isEmptyString(emailTemplateId), [emailTemplateId]);

  const validSmsTemplate = React.useMemo(() => !isEmptyString(smsTemplateId), [smsTemplateId]);

  const saveButtonDisabled = React.useMemo(
    () => !(validUserId && validApplicationId),
    [validUserId, validApplicationId]);

  const completedSteps = React.useMemo(() => {
    if (steps.length === 0) {
      return [];
    }
    return steps.filter(step => {
      switch (step) {
        case NewUserWizardStep.USER_INFO:
          return validUserId;
        case NewUserWizardStep.APPLICATION:
          return validApplicationId;
        case NewUserWizardStep.EMAIL_TEMPLATE:
          return validEmailTemplate;
        case NewUserWizardStep.SMS_TEMPLATE:
          return validSmsTemplate;
        default:
          return false;
      }
    });
  }, [steps, validUserId, validApplicationId, validEmailTemplate, validSmsTemplate]);

  const disabledSteps = React.useMemo(() => {
    if (!validUserId) {
      return steps.filter(step => {
        switch (step) {
          case NewUserWizardStep.USER_INFO:
            return false;
          default:
            return true;
        }
      });
    }
    if (!validApplicationId) {
      return steps.filter(step => {
        switch (step) {
          case NewUserWizardStep.USER_INFO:
            return false;
          case NewUserWizardStep.APPLICATION:
            return false;
          default:
            return true;
        }
      });
    }
    return [];
  }, [steps, validUserId, validApplicationId]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={className}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      saveButtonLabel={saveButtonLabel}
      currentState={currentState}
      defaultState={defaultState}
      steps={steps}
      disabledSteps={disabledSteps}
      completedSteps={completedSteps}
      saveButtonDisabled={saveButtonDisabled}
      showLoadingIndicator={showLoadingIndicator}
      mapStepToLabel={mapStepToLabel}
      mapStepToView={mapStepToView}
      onChangeCurrentStep={reset}
      onSuccess={onSuccess}
      save={inviteUser}
      reset={reset}
    >
      {children}
    </SubmitApiRequestView>
  );
};

export default InviteUserContainer;
