import {
  createQueryParams,
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withApplicationIdHeader,
  withAuthToken,
  withRequiredArguments,
} from "@network/helpers";
import { isEmptyString, isValidInteger } from "@util";
import { GetUsersResponse } from "@network";

const DEFAULT_GET_USERS_LIMIT = 100;

const USER_IDM_MANAGER_URL = process.env.REACT_APP_USER_IDM_MANAGER_REGIONAL || "";

if (isEmptyString(USER_IDM_MANAGER_URL)) {
  throw new Error("Missing Environment Variable: REACT_APP_USER_IDM_MANAGER_REGIONAL");
}

const REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

if (isEmptyString(REGIONAL_API)) {
  throw new Error("Missing Environment Variable: REACT_APP_REGIONAL_API");
}

export type SearchUsersRequestBody = {
  orJoinOperator?: boolean;
  queryCollection: {
    attributeName: string;
    attributeCriteria: string;
  }[];
};

export interface InviteUserResponse {
  userId: string;
  anonymousId: string;
  updatedAt: string;
}

export const lockUnlockUser = (authToken: string,
                               userId: string,
                               json: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["User Lock", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/${userId}/administration/locking`;

    const settings = {
      method: "PUT",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
      body: json,
    };

    const defaultErrorMessage = "Failed to lock/unlock user";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const changeUserPassword = (authToken?: string,
                                   userId?: string,
                                   json?: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["New Password", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/${userId}/administration/password`;

    const settings = {
      method: "PUT",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
      body: json,
    };

    const defaultErrorMessage = "Failed to change user password";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const passwordRecovery = (authToken?: string,
                                 userId?: string,
                                 json?: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["User ID", userId],
      ["Email Template ID", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/${userId}/administration/password/recover`;

    const settings = {
      method: "POST",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
      body: json,
    };

    const defaultErrorMessage = "Failed to send password recovery email";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getUsersWithFilter = (authToken: string,
                                   requestBody: SearchUsersRequestBody,
                                   next: string = "",
                                   limit: number = DEFAULT_GET_USERS_LIMIT): Promise<GetUsersResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const queryParams = createQueryParams({
      next,
      ...(!isValidInteger(limit) ? {} : {limit: Math.max(1, Math.min(DEFAULT_GET_USERS_LIMIT, limit))}),
    });

    const url = `${USER_IDM_MANAGER_URL}/security/identity/v1/users/administration/search${queryParams}`;

    const settings = {
      method: "POST",
      headers: withApplicationIdHeader({
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      }),
      body: JSON.stringify(requestBody),
    };

    const defaultErrorMessage = "Fetch users failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const inviteUser = (authToken: string,
                           applicationId: string,
                           json: string): Promise<InviteUserResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      ["Application ID", applicationId],
      ["Request Body", json],
    ]));

  const makeRequest = () => {

    const url = `${REGIONAL_API}/security/identity/v1/users/administration/invite`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-IoT-Platform-ApplicationId": applicationId,
      },
      body: json,
    };

    const defaultErrorMessage = "Failed to invite user";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
