import { Record } from "immutable";
import { getStringValueNoTrim, isEmptyString } from "@util";

export interface SmsBodyAttributes {
  textBody: string;
  description: string;
}

export class SmsBody extends Record({
  textBody: "",
  description: "",
}) implements SmsBodyAttributes {

  public static EMPTY = new SmsBody();

  public readonly textBody: string;
  public readonly description: string;

  public getTextBody(): string {
    return getStringValueNoTrim(this.textBody);
  }

  public hasTextBody(): boolean {
    return !isEmptyString(this.getTextBody());
  }

  public getDescription(): string {
    return getStringValueNoTrim(this.description);
  }

  public hasDescription(): boolean {
    return !isEmptyString(this.getDescription());
  }
}

export default SmsBody;
