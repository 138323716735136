import React from "react";
import classnames from "classnames";
import { noop } from "@util/Functions";
import { editUserProfile as styles } from "./styles";
import {
  DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES,
  EmailTemplateLanguage,
  UserProfileAttribute,
} from "@data";
import {
  TextFieldStyled,
  UserProfileAttributeListItem,
  UserProfileAttributeListItemProps,
} from "@components";
import UserProfileAttributesListColumn from "./UserProfileAttributesListColumn";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { formEventHandler } from "@util";
import FormControl from "@material-ui/core/FormControl";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface EditUserProfileAttributeListItem extends UserProfileAttributeListItemProps {
  readOnlyValues?: string[];
  setValue?: (value: string) => void;
}

type Props = WithStyles<typeof styles> & EditUserProfileAttributeListItem;

export const EditUserProfileAttributeListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    readOnlyValues = DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES,
    setValue = noop,
    ...otherProps
  } = props;

  const { column, attribute = UserProfileAttribute.EMPTY } = otherProps;

  // Language attribute options

  const mapLanguageToOptionName = (value: EmailTemplateLanguage) => {
    switch (value) {
      case EmailTemplateLanguage.NONE:
        return "None";
      case EmailTemplateLanguage.EN_US:
        return "English-US(en-US)";
      case EmailTemplateLanguage.ES_ES:
        return "Spanish-ES(es-ES)";
      case EmailTemplateLanguage.FR_FR:
        return "French-FR(fr-FR)";
      case EmailTemplateLanguage.DE_DE:
        return "German-DE(de-DE)";
      case EmailTemplateLanguage.ZH_TW:
        return "Chinese Traditional(zh-TW)";
      default:
        return null;
    }
  };

  const languageMenuItems = Object.values(EmailTemplateLanguage).map(value => {
    return (
      <MenuItem key={value} value={value}>{mapLanguageToOptionName(value)}</MenuItem>
    );
  });

  if (UserProfileAttributesListColumn.VALUE === column) {

    const name = attribute.getName();

    if (name === "language") {
      return (
        <FormControl className={classnames("formControl", classes.select, name)}>
          <Select
            value={attribute.getValueAsString()}
            onChange={formEventHandler(setValue)}
          >
            {languageMenuItems}
          </Select>
        </FormControl>
      );
    }

    return (
      <TextFieldStyled
        className={classnames("profileAttributeTextField", name)}
        name={name}
        disabled={readOnlyValues.indexOf(name) >= 0}
        value={attribute.getValueAsString()}
        setValue={setValue}
      />
    );
  }

  return <UserProfileAttributeListItem {...otherProps} />;
});

export default EditUserProfileAttributeListItem;
