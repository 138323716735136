import { EmailTemplateLanguage, SmsTemplate } from "@data";
import { makeApiRequestAndComplete, makeJsonApiRequest, withAuthToken, withRequiredArguments } from "./helpers";

const REACT_APP_REGIONAL_API = process.env.REACT_APP_REGIONAL_API || "";

export interface CreateSmsTemplateResponse {
  id: string;
}

export interface GetSmsTemplateLanguagesResponse {
  languages: EmailTemplateLanguage[];
}

export const getSmsTemplates = (authToken: string): Promise<SmsTemplate[]> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/sms-communication/templates`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch SMS templates failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSmsTemplate = (authToken: string, templateId: string): Promise<SmsTemplate> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Template ID", templateId ],
    ]));

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/sms-communication/templates/${templateId}`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch SMS template by ID failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const createSmsTemplate = (authToken: string, template: string): Promise<CreateSmsTemplateResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Template", template ],
    ]));

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/sms-communication/templates`;

    const settings = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
      body: template
    };

    const defaultErrorMessage = "Create SMS template failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const getSmsTemplateLanguages = (authToken: string): Promise<GetSmsTemplateLanguagesResponse> => {

  const validate = () => withAuthToken(authToken);

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/sms-communication/templates/supported/languages`;

    const settings = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Fetch supported SMS template languages failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const editSmsTemplate = (authToken: string,
                                templateId: string,
                                template: string): Promise<CreateSmsTemplateResponse> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Template ID", templateId ],
      [ "Template", template ],
    ]));

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/sms-communication/templates/${templateId}`;

    const settings = {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
      body: template
    };

    const defaultErrorMessage = "Edit SMS template failed";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const deleteSmsTemplate = (authToken: string,
                                templateId: string): Promise<void> => {

  const validate = () => withAuthToken(authToken)
    .then(() => withRequiredArguments([
      [ "Template ID", templateId ],
    ]));

  const makeRequest = () => {

    const url = `${REACT_APP_REGIONAL_API}/security/management/v1/user-interactions/sms-communication/templates/${templateId}`;

    const settings = {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Delete SMS template failed";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};
