import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 13,
    },
    formHelperTextError: {
      marginBottom: 15,
    },
  });

export default styles;
