import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSmsTemplateClient,
} from "@hooks";
import { SmsTemplate, SmsTemplateAttributes } from "@data";

type SuccessResponse = SmsTemplateAttributes;

export interface UseGetSmsTemplateProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  templateId: string;
}

export interface UseGetSmsTemplateModel extends UseApiRequestModel<SuccessResponse> {
  smsTemplate: SmsTemplate;
}

export interface UseGetSmsTemplateActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetSmsTemplateProps;
type Model = UseGetSmsTemplateModel;
type Actions = UseGetSmsTemplateActions;
type Result = [Model, Actions];

export const useGetSmsTemplate = (props: Props): Result => {

  const {
    templateId,
    defaultErrorMessage = "Failed to get SMS template",
    ...otherProps
  } = props;

  const SmsTemplateClient = useSmsTemplateClient();

  const makeApiRequest = React.useCallback(
    () => SmsTemplateClient.getSmsTemplate(templateId),
    [SmsTemplateClient, templateId]);

  const [{successResponse, ...baseModel}, { ...baseActions} ] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const smsTemplate = React.useMemo(() => {
    return successResponse ? new SmsTemplate(successResponse) : SmsTemplate.EMPTY;
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    smsTemplate,
  }), [
    baseModel,
    smsTemplate,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetSmsTemplate;
