import React from "react";
import { RouteParams } from "./constants";
import { useParams } from "react-router-dom";
import useSelectedTab from "@modules/qualityReports/useSelectedTab";
import { QualityReportPlatformDomainId } from "@data/QualityReport";
import QualityReportDetails from "./containers/QualityReportDetails";
import WaitForApiRequestView from "@components/wait-for-api-request-view";
import useGetQualityReport from "@hooks/portalQualityReports/use-get-quality-report";
import { QualityReportsTab } from "@modules/qualityReports/components/QualityReportsTabs";

export function QualityReportDetailsView() {

  const {
    groupId,
    version,
  } = useParams<RouteParams>();

  const selectedTab = useSelectedTab();

  const platformDomainId = React.useMemo(() => {
    switch (selectedTab) {
      case QualityReportsTab.DATA_AND_ANALYTICS:
        return QualityReportPlatformDomainId.DATA_AND_ANALYTICS;
      case QualityReportsTab.COMMON_SERVICES:
        return QualityReportPlatformDomainId.COMMON_SERVICES;
      case QualityReportsTab.DEVICE_MANAGEMENT:
        return QualityReportPlatformDomainId.DEVICE_MANAGEMENT;
      case QualityReportsTab.SECURITY_SERVICES:
        return QualityReportPlatformDomainId.SECURITY_SERVICES;
      case QualityReportsTab.SELF_SERVICE_TOOLS:
        return QualityReportPlatformDomainId.SELF_SERVICE_TOOLS;
      default:
        return QualityReportPlatformDomainId.NONE;
    }
  }, [selectedTab]);

  const [{ report, showSuccessView, ...model }, { refresh, ...actions }] = useGetQualityReport({
    platformDomainId,
    groupId,
    version,
  });

  return (
    <WaitForApiRequestView
      loadingMessage="Loading Quality Report..."
      errorTitle="Failed to load quality report"
      showSuccessView={showSuccessView}
      refresh={refresh}
      retry={refresh}
      {...model}
      {...actions}
    >
      {showSuccessView && (
        <QualityReportDetails
          report={report}
        />
      )}
    </WaitForApiRequestView>
  );
}

export default QualityReportDetailsView;
