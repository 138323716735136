import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { SummaryViewData } from "@data";
import { useGetSmsTemplate } from "@hooks";
import { ActionMenuItem, DeleteSmsTemplateDialog, DetailsView, DetailsViewRoute, SummaryView } from "@components";
import { PrimaryIcon } from "@modules/smsTemplates/styles";
import { RouteParams } from "./constants";
import { DELETE_SMS_TEMPLATE, EDIT_SMS_TEMPLATE, SmsTemplateAction } from "@modules/smsTemplates/SmsTemplatesView";
import { MODULE_PATH as SMS_TEMPLATES_PATH } from "@modules/smsTemplates/constants";
import { noop } from "@util";
import { getEditSmsTemplatePath } from "@modules/smsTemplateWizard/helpers";

export function SmsTemplateDetailsView() {
  const { templateId } = useParams<RouteParams>();
  const history = useHistory();

  const showSmsTemplates = React.useCallback(() => history.push(SMS_TEMPLATES_PATH), [history]);
  const showEditSmsTemplate = React.useCallback(() => history.push(getEditSmsTemplatePath(templateId))
    , [history, templateId]);

  const [{ smsTemplate, ...otherProps }, { refresh }] = useGetSmsTemplate({templateId});

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const summaryItems = React.useMemo(() => [
    new SummaryViewData({
      className: "templateId",
      name: "Template ID",
      value: templateId,
    }),
    new SummaryViewData({
      className: "language",
      name: "Language",
      value: smsTemplate.getLanguage(),
    }),
    new SummaryViewData({
      className: "messageType",
      name: "Message Type",
      value: smsTemplate.getMessageType(),
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: smsTemplate.getDescription(),
    }),
    new SummaryViewData({
      className: "textBody",
      name: "Text Body",
      value: smsTemplate.getTextBody(),
    }),
  ], [templateId, smsTemplate]);

  const TemplateSummaryView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SummaryView
        items={summaryItems}
      />
    );
  }, [summaryItems]);

  const route: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summaryView",
      name: "Summary",
      view: TemplateSummaryView,
    }
  ], [TemplateSummaryView]);

  const onClickAction = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case SmsTemplateAction.EDIT_TEMPLATE:
        return showEditSmsTemplate();
      case SmsTemplateAction.DELETE_TEMPLATE:
        return setShowDeleteDialog(true);
      default:
        return noop;
    }
  }, [showEditSmsTemplate, setShowDeleteDialog]);

  return (
    <React.Fragment>
      <DetailsView
        {...otherProps}
        title={templateId}
        routes={route}
        icon={PrimaryIcon}
        tabsMarginTop={false}
        actions={[EDIT_SMS_TEMPLATE, DELETE_SMS_TEMPLATE]}
        onClickAction={onClickAction}
        refresh={refresh}
      />
      <DeleteSmsTemplateDialog
        open={showDeleteDialog}
        templateId={templateId}
        cancel={() => setShowDeleteDialog(false)}
        onSuccessMessageShown={showSmsTemplates}
      />
    </React.Fragment>
  );
}

export default SmsTemplateDetailsView;
