import { Record } from "immutable";
import EmailTemplate from "@data/EmailTemplate";
import { getStringValue, isEmptyString } from "@util";
import EmailBody, { EmailBodyAttributes } from "@data/EmailBody";

export enum EmailTemplateLanguage {
  NONE = "",
  BG_BG = "bg-BG",
  CS_CZ = "cs-CZ",
  DA_DK = "da-DK",
  DE_DE = "de-DE",
  EN_US = "en-US",
  ES_ES = "es-ES",
  FI_FI = "fi-FI",
  FR_FR = "fr-FR",
  HU_HU = "hu-HU",
  IT_IT = "it-IT",
  NL_NL = "nl-NL",
  NO_NO = "no-NO",
  PL_PL = "pl-PL",
  PT_PT = "pt-PT",
  SK_SK = "sk-SK",
  SV_SE = "sv-SE",
  ZH_CN = "zh-CN",
  ZH_TW = "zh-TW",
}

export enum EmailTemplateMessageType {
  NONE = "",
  MESSAGE_SIGNUP = "MESSAGE_SIGNUP",
  MESSAGE_SIGNUP_FEDERATION = "MESSAGE_SIGNUP_FEDERATION",
  MESSAGE_SIGNUP_CONFIRMATION = "MESSAGE_SIGNUP_CONFIRMATION",
  MESSAGE_RESEND_CODE = "MESSAGE_RESEND_CODE",
  MESSAGE_RESEND_CODE_FEDERATION = "MESSAGE_RESEND_CODE_FEDERATION",
  MESSAGE_FORGET_PASSWORD = "MESSAGE_FORGET_PASSWORD",
  MESSAGE_INVITE_EXISTING_USER = "MESSAGE_INVITE_EXISTING_USER",
  MESSAGE_USER_ALREADY_CONFIRMED = "MESSAGE_USER_ALREADY_CONFIRMED",
  MESSAGE_ACCOUNT_CLOSED = "MESSAGE_ACCOUNT_CLOSED",
  MESSAGE_ACCOUNT_LOCKED = "MESSAGE_ACCOUNT_LOCKED",
  MESSAGE_ACCOUNT_UNLOCKED = "MESSAGE_ACCOUNT_UNLOCKED",
  MESSAGE_CUSTOM_TEMPLATE = "MESSAGE_CUSTOM_TEMPLATE",
}

export interface EmailTemplateRequestAttributes {
  applicationId: string;
  language: EmailTemplateLanguage;
  messageType: EmailTemplateMessageType;
  body: EmailBodyAttributes;
}

export class EmailTemplateRequest extends Record({
  applicationId: "",
  language: EmailTemplateLanguage.EN_US,
  messageType: EmailTemplateMessageType.MESSAGE_SIGNUP,
  body: EmailBody.EMPTY.toJS(),
}) implements EmailTemplateRequestAttributes {

  public static EMPTY: EmailTemplateRequest = new EmailTemplateRequest();

  public readonly applicationId: string;
  public readonly language: EmailTemplateLanguage;
  public readonly messageType: EmailTemplateMessageType;
  public readonly body: EmailBodyAttributes;

  public static from(emailTemplate: EmailTemplate): EmailTemplateRequest {
    return new EmailTemplateRequest({
      applicationId: emailTemplate.getApplicationId(),
      language: emailTemplate.getLanguage() || EmailTemplateLanguage.EN_US,
      messageType: emailTemplate.getMessageType() || EmailTemplateMessageType.MESSAGE_SIGNUP,
      body: emailTemplate.getBody().toJS(),
    });
  }

  public getLanguage(): EmailTemplateLanguage {
    return this.language;
  }

  public getMessageType(): EmailTemplateMessageType {
    return this.messageType;
  }

  public getApplicationId(): string {
    return getStringValue(this.applicationId);
  }

  public hasApplicationId(): boolean {
    return !isEmptyString(this.getApplicationId());
  }

  public getBody(): EmailBody {
    return new EmailBody(this.body);
  }

  public getTextBody(): string {
    return this.getBody().getTextBody();
  }

  public getHtmlBody(): string {
    return this.getBody().getHtmlBody();
  }

  public hasHtmlBody(): boolean {
    return this.getBody().hasHtmlBody();
  }

  public getDescription(): string {
    return this.getBody().getDescription();
  }

  public getEmailSubject(): string {
    return this.getBody().getEmailSubject();
  }
}

export default EmailTemplateRequest;
