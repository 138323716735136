import React from "react";
import classnames from "classnames";
import { UserIdentityType } from "@data";
import { noop, scrollToTop } from "@util";
import Paper from "@material-ui/core/Paper";
import { userInfo as styles } from "./styles";
import Label from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import UserIdTextField from "../components/UserIdTextField";

// Only show verbose loading message if request has been in progress for this long
const DELAYED_LOADING_MESSAGE_DELAY_MS = 1000;

export interface Model {
  identityType?: UserIdentityType;
  userId?: string;
  userIdErrorMessage?: string;
  showLoadingIndicator?: boolean;
}

export interface Actions {
  setUserId?: (userId: string) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const UserInfo = withStyles(styles)((props: Props) => {

  const {
    classes,
    identityType = UserIdentityType.EMAIL,
    userId = "",
    userIdErrorMessage,
    showLoadingIndicator = false,
    setUserId = noop,
    children,
  } = props;

  const [showDelayedLoadingMessage, setShowDelayedLoadingMessage] = React.useState(false);

  const userIdTextFieldExtraProps = React.useMemo(() => {
    switch (identityType) {
      case UserIdentityType.PHONE:
        return {
          type: "tel",
          name: "userId",
          label: "User Phone Number",
          placeholder: "+1-555-555-5555",
          helperText: "Format: +1-555-555-5555",
        };
      case UserIdentityType.EMAIL:
      default:
        return {
          type: "email",
          name: "userId",
          label: "User Email Address",
          placeholder: "someone@example.com",
        };
    }
  }, [identityType]);

  // Make sure the user sees the delayed loading message when it appears
  React.useLayoutEffect(() => scrollToTop(), [showDelayedLoadingMessage]);

  React.useEffect(() => {

    if (!showLoadingIndicator) {
      setShowDelayedLoadingMessage(false);
      return () => noop;
    }

    // Wait 5 seconds before showing delayed loading message - in case of slow requests
    // to prevent user from accidentally thinking the request is hung, when it can sometimes
    // take up to 60 seconds for the user to be invited; other times it can take 2 seconds.
    const timer = setTimeout(() =>
      setShowDelayedLoadingMessage(true), DELAYED_LOADING_MESSAGE_DELAY_MS);

    return () => clearTimeout(timer);

  }, [showLoadingIndicator, setShowDelayedLoadingMessage]);

  return (
    <div className={classnames("userInfo", classes.container)}>
      {showLoadingIndicator && (
        <React.Fragment>
          {showDelayedLoadingMessage && (
            <Paper
              className={classnames("loadingMessage", classes.loadingMessage)}
              elevation={5}
            >
              <Label className={classes.loadingMessageLabel} variant="h1" color="inherit">
                Inviting User... Please wait...
              </Label>
              <Label className={classes.loadingMessageLabel} variant="h4" color="inherit">
                This process can sometimes take up to 60 seconds
              </Label>
              <Label className={classes.loadingMessageLabel} variant="subtitle1" color="inherit">
                Please avoid reloading the page while this request is in progress to ensure
                that you receive confirmation that the user was invited.
              </Label>
            </Paper>
          )}
          <div className={classnames("modal", classes.modal)} />
        </React.Fragment>
      )}
      <UserIdTextField
        {...userIdTextFieldExtraProps}
        className={classnames("userId", classes.userId)}
        errorMessage={userIdErrorMessage}
        disabled={showLoadingIndicator}
        userId={userId}
        setUserId={setUserId}
      />
      {children}
    </div>
  );
});

export default UserInfo;
