import React from "react";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import UserIdentityType from "@data/UserIdentityType";
import Typography from "@material-ui/core/Typography";
import EmailTemplateIcon from "@material-ui/icons/Email";
import SmsTemplateIcon from "@material-ui/icons/Textsms";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface SelectUserIdentityTypeModel {
  className?: string;
  title?: string;
}

export interface SelectUserIdentityTypeActions {
  setIdentityType?: (userIdentityType: UserIdentityType | null) => void;
}

type Model = SelectUserIdentityTypeModel;
type Actions = SelectUserIdentityTypeActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SelectUserIdentityType = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "How would you like to invite this user?",
    setIdentityType = noop,
    children,
  } = props;

  return (
    <div className={classnames("selectUserIdentityType", className, classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h1">
        {title}
      </Typography>
      <div className={classnames("cards", classes.cards)}>
        <div
          className={classnames("emailCard", classes.card)}
          onClick={clickHandler(() => setIdentityType(UserIdentityType.EMAIL))}
        >
          <div className={classnames("cardHeader", classes.cardHeader)}>
            <EmailTemplateIcon className={classnames("emailTemplateIcon", classes.cardIcon)} />
            <Typography className={classnames("cardTitle", classes.cardTitle)} variant="h3">
              Email
            </Typography>
          </div>
          <Typography className={classnames("emailCardDescription", classes.cardDescription)} variant="body1">
            User will be emailed an invitation to join the platform using an email template of your choice.
          </Typography>
        </div>
        <div
          className={classnames("smsCard", classes.card)}
          onClick={clickHandler(() => setIdentityType(UserIdentityType.PHONE))}
        >
          <div className={classnames("cardHeader", classes.cardHeader)}>
            <SmsTemplateIcon className={classnames("smsTemplateIcon", classes.cardIcon)} />
            <Typography className={classnames("cardTitle", classes.cardTitle)} variant="h3">
              SMS
            </Typography>
          </div>
          <Typography className={classnames("smsCardDescription", classes.cardDescription)} variant="body1">
            User will be texted an invitation to join the platform using an SMS template of your choice.
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
});

export default SelectUserIdentityType;
