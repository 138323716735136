import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./SmsTemplatesView";
import { isSmsTemplateEnabled } from "@util";

export const smsTemplatesModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "SMS Templates",
  title: "SMS Template Management",
  className: "smsTemplatesModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  disabled: !isSmsTemplateEnabled(),
});

export default smsTemplatesModule;
