import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import { validationView as styles } from "./styles";
import Typography from "@material-ui/core/Typography";
import { AlertSeverity, ErrorView, MissingAlertView } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface ValidationViewModel {
  showCodeError?: boolean;
  missingCode?: boolean;
  workloadFailure?: boolean;
  queryWorkload?: boolean;
  pythonWorkload?: boolean;
  failureReason?: string;
}

export interface ValidationViewActions {
  uploadCode?: () => void;
}

type Props = WithStyles<typeof styles> & ValidationViewModel & ValidationViewActions;

export const ValidationView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showCodeError,
    missingCode,
    workloadFailure,
    queryWorkload,
    pythonWorkload,
    failureReason = "",
    uploadCode = noop,
  } = props;

  if (!showCodeError && !missingCode && !workloadFailure) {
    return null;
  }

  return (
    <div className={classnames("validationView", classes.container)}>
      {!queryWorkload && showCodeError && (
        <ErrorView
          className="codeError"
          message="Unable to check if code has been uploaded for the workload."
        />
      )}
      {!queryWorkload && missingCode && !pythonWorkload && (
        <MissingAlertView
          className={"codeNotUploadedAlert"}
          message={(
            <Typography variant="body1">
              You must upload code in order to release or test this workload.
            </Typography>
          )}
          actionLabel="UPLOAD CODE"
          action={() => uploadCode()}
        />
      )}
      {!queryWorkload && missingCode && pythonWorkload && (
        <MissingAlertView
          className={"codeNotUploadedAlert"}
          message={(
            <Typography variant="body1">
              Navigate to Code tab to manage code for this workload.
            </Typography>
          )}
          showAction={false}
        />
      )}
      {workloadFailure && (
        <MissingAlertView
          className={"failureReasonBanner"}
          severity={AlertSeverity.ERROR}
          message={(
            <Typography variant="body1">
              Failure Reason: {failureReason}
            </Typography>
          )}
        />
      )}
    </div>
  );
});

export default ValidationView;
