import { createStyles } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/VerifiedUser";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const serviceInfo = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      marginTop: 8,
    },
    dropdown: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      minWidth: 250,
      width: "fit-content",
    },
    languageContainer: {
      marginBottom: 40,
    },
    messageContainer: {
      marginBottom: 20,
    },
    textFieldContainer: {
      marginTop: 20,
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 13,
    },
    formHelperTextError: {
      marginBottom: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
  });

export const applicationView = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontSize: 24,
      alignSelf: "flex-start",
      fontWeight: 300,
      marginTop: 6,
    },
    applications: {
    },
  },
);

export const reviewView = () =>
  createStyles({
      container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        justifyContent: "flex-start",
        marginTop: 8,
        "& label.title": {
          marginBottom: 10,
        }
      },
    }
  );

export default serviceInfo;
