import { MODULE_PATH as BASE_URL } from "@modules/smsTemplates/constants";

export const MODULE_ID = "sms_template_details";
export const MODULE_PATH = `${BASE_URL}/:templateId`;

export interface RouteParams {
  templateId: string;
}

export { BASE_URL };
