import { CostReportingPeriodType } from "@data";

export const formatMetricDate = (
    periodType: CostReportingPeriodType = CostReportingPeriodType.DAILY,
    dateString: string = "",
) => {
    switch (periodType) {
        case CostReportingPeriodType.DAILY:
            return new Date(dateString).toLocaleDateString("en-US",
                { day: "numeric", month: "long", year: "numeric", timeZone: "UTC" });
        case CostReportingPeriodType.MONTHLY:
            return new Date(dateString).toLocaleDateString("en-US",
                { month: "long", year: "numeric", timeZone: "UTC" });
        case CostReportingPeriodType.YEARLY:
            return new Date(dateString).toLocaleDateString("en-US",
                { year: "numeric", timeZone: "UTC" });
        default:
            return dateString + "";
    }
};
