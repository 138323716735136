import { Record } from "immutable";
import { getStringValue } from "@util";
import {
  UpdateUserProfileAttributeValues,
  UpdateUserProfileRequest,
  UpdateUserProfileRequestAttributes,
  UserProfileAttributesMap,
} from "@data";

export interface InviteUserRequestAttributes extends UpdateUserProfileRequestAttributes {
  emailTemplateId: string;
}

export class InviteUserRequest extends Record({
  ...UpdateUserProfileRequest.EMPTY.toJS(),
  emailTemplateId: "",
}) implements InviteUserRequestAttributes {

  public static EMPTY = new InviteUserRequest();

  public readonly profile: UpdateUserProfileAttributeValues;
  public readonly emailTemplateId: string;

  public static from(attributes: UserProfileAttributesMap,
                     emailTemplateId: string = "",
                     readOnlyValues?: string[]): InviteUserRequest {

    return new InviteUserRequest({
      ...UpdateUserProfileRequest.from(attributes, readOnlyValues).toJS(),
      emailTemplateId,
    });
  }

  public getEmailTemplateId(): string {
    return getStringValue(this.emailTemplateId);
  }

  public toJson(): string {
    try {
      return JSON.stringify(this.toJS());
    } catch {
      return "{}";
    }
  }
}

export default InviteUserRequest;
