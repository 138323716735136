import { Record } from "immutable";
import { getStringValue } from "@util";

export enum CostReportingUnit {
  COST = "COST",
  COUNT = "COUNT",
}

export enum CostReportingPeriodType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY"
}

export enum CostReportingMetricFilter {
  CATEGORY_TYPE = "CATEGORY_TYPE",
  DOMAIN_TYPE = "DOMAIN_TYPE",
  GROUP_TYPE = "GROUP_TYPE",
  METRIC_TYPE = "METRIC_TYPE",
  RESOURCE_TYPE = "RESOURCE_TYPE",
}

export enum CostReportingResourceType {
  USER = "USER",
  BYTES = "BYTES",
  DURATION = "DURATION",
  SERVICE = "SERVICE",
  API = "API",
}

export interface CostReportingMetricAttributes {
  accountId: string;
  category: string;
  date: string;
  domain: string;
  group: string;
  metricType: string;
  periodType: CostReportingPeriodType;
  resourceType: CostReportingResourceType;
  costValue: number;
  countValue: number;
}

export class CostReportingMetric extends Record({
  accountId: "",
  category: "",
  date: "",
  domain: "",
  group: "",
  metricType: "",
  periodType: CostReportingPeriodType.MONTHLY,
  resourceType: CostReportingResourceType.USER,
  costValue: 0.0,
  countValue: 0,
}) implements CostReportingMetricAttributes {

  public static EMPTY: CostReportingMetric = new CostReportingMetric();

  public readonly accountId: string;
  public readonly category: string;
  public readonly date: string;
  public readonly domain: string;
  public readonly group: string;
  public readonly metricType: string;
  public readonly periodType: CostReportingPeriodType;
  public readonly resourceType: CostReportingResourceType;
  public readonly costValue: number;
  public readonly countValue: number;

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getCategory(): string {
    return getStringValue(this.category);
  }

  public getDate(): string {
    return getStringValue(this.date);
  }

  public getDomain(): string {
    return getStringValue(this.domain);
  }

  public getGroup(): string {
    return getStringValue(this.group);
  }

  public getMetricType(): string {
    return getStringValue(this.metricType);
  }

  public getPeriodType(): CostReportingPeriodType {
    return this.periodType;
  }

  public getResourceType(): CostReportingResourceType {
    return this.resourceType;
  }

  public getCostValue(): number {
    return this.costValue;
  }

  public getCountValue(): number {
    return this.countValue;
  }
}

export default CostReportingMetric;
