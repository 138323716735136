import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    identityTypeDropdown: {
      flex: "1 0 auto",
      "& div.dropdownMenu": {
        margin: "8px 0px 0px 0px",
      },
    },
  });

export default styles;
