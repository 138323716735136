import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { getPathToSmsTemplate } from "@modules/smsTemplateDetails/helpers";
import CreateSmsTemplate from "./containers/CreateSmsTemplate";
import { RouteParams } from "@modules/smsTemplateDetails/constants";

export function EditSmsTemplateView() {
  const { templateId } = useParams<RouteParams>();

  const history = useHistory();

  const cancel = React.useCallback(() => history.goBack(), [history]);

  const showTemplateDetails = React.useCallback((id: string) =>
    history.replace(getPathToSmsTemplate(id)), [history]);

  return (
    <CreateSmsTemplate
      editMode={true}
      templateId={templateId}
      cancel={cancel}
      showTemplateDetails={showTemplateDetails}
    />
  );
}

export default EditSmsTemplateView;
