import { Record } from "immutable";
import { UserProfileAttribute, UserProfileAttributesMap } from "@data/UserProfileAttribute";

export const EMAIL_IDENTITY_READ_ONLY_USER_PROFILE_ATTRIBUTES = [
  "email",
];

export const PHONE_IDENTITY_READ_ONLY_USER_PROFILE_ATTRIBUTES = [
  "phone_number",
];

export const DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES =
  EMAIL_IDENTITY_READ_ONLY_USER_PROFILE_ATTRIBUTES;

export interface UpdateUserProfileAttributeValues {
  [key: string]: {
    type: string;
    value: string | number | boolean | null;
  };
}

export interface UpdateUserProfileRequestAttributes {
  profile: UpdateUserProfileAttributeValues;
}

export class UpdateUserProfileRequest extends Record({
  profile: {},
}) implements UpdateUserProfileRequestAttributes {

  public static EMPTY = new UpdateUserProfileRequest();

  public readonly profile: UpdateUserProfileAttributeValues;

  public static from(attributes: UserProfileAttributesMap,
                     readOnlyValues: string[] = DEFAULT_READ_ONLY_USER_PROFILE_ATTRIBUTES): UpdateUserProfileRequest {

    return new UpdateUserProfileRequest({
      profile: Object.keys(attributes)
        .filter(key => readOnlyValues.indexOf(key) === -1)
        .map(key => attributes[key])
        .reduce((profile, attribute: UserProfileAttribute) => {
          profile[attribute.getName()] = {
            type: attribute.getDataType(),
            value: attribute.getValue(),
          };
          return profile;
        }, {}),
    });
  }

  public toJson(): string {
    try {
      return JSON.stringify(this.toJS());
    } catch {
      return "{}";
    }
  }
}

export default UpdateUserProfileRequest;
