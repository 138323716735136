import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    title: {
      color: Color.TEXT,
      fontSize: 28,
      fontWeight: 300,
      flex: "1 0 auto",
      alignSelf: "stretch",
      borderBottom: `1px solid ${Color.GREY1}`,
      textAlign: "center",
      paddingBottom: 15,
    },
    cards: {
      flex: "1 0 250px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      justifyContent: "space-between",
      marginTop: 25,
      padding: 25,
      backgroundColor: Color.LIGHT_GREY6,
      boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.5)",
    },
    card: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      margin: 15,
      padding: 15,
      border: `1px solid ${Color.GREY1}`,
      borderRadius: 6,
      cursor: "pointer",
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      backgroundColor: Color.WHITE,
      "&:hover, &:active": {
        backgroundColor: "#232f3e",
        color: "#fff",
        "& $cardIcon": {
        },
        "& $cardTitle": {
          color: "#fff",
        }
      },
    },
    cardHeader: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    cardIcon: {
      width: 50,
      height: "auto",
      color: Color.SIGNIFY_GREEN,
    },
    cardTitle: {
      marginLeft: 15,
      color: Color.TEXT,
      fontSize: 24,
    },
    cardDescription: {
      marginLeft: 18,
      fontSize: 16,
      lineHeight: "32px",
    },
  });

export default styles;
