import React from "react";
import { noop } from "@util";
import styles from "./styles";
import classnames from "classnames";
import GroupedMetricGraph from "./GroupedMetricGraph";
import { CostReportingList, DropdownMenu } from "@components";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { UseGetCostReportingMetricsActions, UseGetCostReportingMetricsModel } from "@hooks/costReporting";
import {
  CostReportingGroupedMetric,
  CostReportingMetricFilter,
  CostReportingPeriodType,
  CostReportingResourceType,
  CostReportingUnit
} from "@data";

export interface CostReportsModel {
  className?: string;
  title?: string;
  showFilters?: boolean;
  graphUnit?: CostReportingUnit;
  periodType?: CostReportingPeriodType;
  resourceType?: CostReportingResourceType;
  metricFilter?: CostReportingMetricFilter;
  metricModel?: UseGetCostReportingMetricsModel;
  groupedMetrics?: CostReportingGroupedMetric[];
  loadingGroupedMetrics?: boolean;
}

export interface CostReportsActions {
  metricActions?: UseGetCostReportingMetricsActions;
  setShowFilters?: (value: boolean) => void;
  setGraphUnit?: (unit: CostReportingUnit) => void;
  setPeriodType?: (periodType: CostReportingPeriodType) => void;
  setResourceType?: (resourceType: CostReportingResourceType) => void;
  setMetricFilter?: (metricFilter: CostReportingMetricFilter) => void;
}

type Model = CostReportsModel;
type Actions = CostReportsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CostReports = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showFilters,
    graphUnit,
    periodType,
    resourceType,
    metricFilter,
    metricModel,
    metricActions,
    groupedMetrics,
    loadingGroupedMetrics,
    setShowFilters = noop,
    setGraphUnit = noop,
    setPeriodType = noop,
    setResourceType = noop,
    setMetricFilter = noop,
    children,
  } = props;

  return (
    <div className={classnames("costReports", className, classes.container)}>
      <div
        className={classnames(
          { [classes.leftContainer]: showFilters },
          { [classes.fullContainer]: !showFilters }
        )}
      >
        <GroupedMetricGraph
          showFilters={showFilters}
          graphUnit={graphUnit}
          periodType={periodType}
          resourceType={resourceType}
          metricFilter={metricFilter}
          groupedMetrics={groupedMetrics}
          loadingGroupedMetrics={loadingGroupedMetrics}
          setShowFilters={setShowFilters}
        />
        <CostReportingList {...metricModel} {...metricActions} />
      </div>
      {showFilters && (
        <div className={classnames("rightContainer", classes.rightContainer)}>
          <div className={classnames("headerFilterCard", classes.headerCard, classes.headerFilterCard)}>
            <div className={classnames("headerFilterCardTitle", classes.headerFilterCardTitle)}>
              Metric Parameters
            </div>
            <DropdownMenu
              className={classnames("metricFilter", classes.dropdown)}
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              fullWidth={true}
              hideEmptyValue={true}
              variant="outlined"
              values={Object.values(CostReportingMetricFilter)}
              dropdownMenuLabel="Partition By"
              dropdownMenuHint="Select how to partition the graph"
              selectedValue={metricFilter}
              setSelectedValue={setMetricFilter}
            />
            <DropdownMenu
              className={classnames("periodType", classes.dropdown)}
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              fullWidth={true}
              hideEmptyValue={true}
              variant="outlined"
              values={Object.values(CostReportingPeriodType)}
              dropdownMenuLabel="Granularity"
              dropdownMenuHint="Filter by a specific granularity"
              selectedValue={periodType}
              setSelectedValue={setPeriodType}
            />
            <DropdownMenu
              className={classnames("resourceType", classes.dropdown)}
              selectClassName={classes.dropdownMenuSelect}
              dropdownMenuLabelClassName={classes.dropdownMenuLabel}
              emptyValueLabelClassName={classes.dropdownMenuEmptyValueLabel}
              fullWidth={true}
              hideEmptyValue={true}
              variant="outlined"
              values={Object.values(CostReportingResourceType)}
              dropdownMenuLabel="Resource"
              dropdownMenuHint="Filter by a specific resource type"
              selectedValue={resourceType}
              setSelectedValue={setResourceType}
            />
            <div className={classnames("graphUnit", classes.graphUnit)}>
              <ToggleButtonGroup
                value={graphUnit}
                exclusive={true}
                onChange={(_, unit) => setGraphUnit(unit)}
                aria-label="Graph Unit"
              >
                <ToggleButton value={CostReportingUnit.COST}>Cost</ToggleButton>
                <ToggleButton value={CostReportingUnit.COUNT}>{resourceType} Count</ToggleButton>
              </ToggleButtonGroup>
              Select the unit of the graph
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

export default CostReports;
