import React from "react";
import { isEmptyString, noop } from "@util";
import { CreateSmsTemplateRequest, EmailTemplateLanguage, SmsBody, SmsMessageType } from "@data";
import SmsTemplateInfo from "../components/SmsTemplateInfo";
import {
  useCreateSmsTemplate,
  useEditSmsTemplate,
  useGetApplicationsRegional,
  useGetSmsTemplate,
  useGetSmsTemplateLanguages,
} from "@hooks";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel
} from "@components/submit-api-request-view";
import { DEFAULT_STEP_LABELS, DEFAULT_STEPS, SmsTemplateWizardStep } from "../helpers";
import ApplicationView from "../components/ApplicationView";
import ReviewView from "../components/ReviewView";

interface ContainerModel extends SubmitApiRequestViewModel<CreateSmsTemplateRequest> {
  templateId?: string;
  editMode?: boolean;
}

interface ContainerActions extends SubmitApiRequestViewActions<CreateSmsTemplateRequest> {
  showTemplateDetails?: (templateId: string) => void;
}

type Props = ContainerModel & ContainerActions;

export const CreateSmsTemplateContainer = (props: Props) => {

  const {
    templateId: currentTemplateId  = "",
    editMode = false,
    showTemplateDetails = noop,
    ...otherProps
  } = props;

  const [{ smsTemplate }] = useGetSmsTemplate({ templateId: currentTemplateId });

  const defaultData = React.useMemo(() => editMode ? CreateSmsTemplateRequest.from(smsTemplate)
    : new CreateSmsTemplateRequest({
      ...CreateSmsTemplateRequest.EMPTY,
      language: EmailTemplateLanguage.EN_US,
      messageType: SmsMessageType.MESSAGE_SIGNUP,
    }), [editMode, smsTemplate]);

  const [data, setData] = React.useState<CreateSmsTemplateRequest>(defaultData);

  const [{ templateId, ...otherModel }, { refresh: createTemplate, reset }] =
    useCreateSmsTemplate({ templateBody: data });

  const [{ templateId: editTemplateId, ...editModel }, { refresh: editTemplate, reset: resetEdit }] =
    useEditSmsTemplate({ templateId: currentTemplateId, templateBody: data });

  const [{ languages, showSuccessView }] = useGetSmsTemplateLanguages();

  const setLanguage = React.useCallback((language: string) =>
    setData((prev) =>
      new CreateSmsTemplateRequest({
        ...prev.toJS(),
        language,
    })), [setData]);

  const setDescription = React.useCallback((description: string) =>
    setData((prev) => new CreateSmsTemplateRequest({
      ...prev.toJS(),
      body: new SmsBody({
        ...prev.getBody().toJS(),
        description,
      }),
    })), [setData]);

  const setBody = React.useCallback((body: string) =>
    setData((prev) => new CreateSmsTemplateRequest({
      ...prev.toJS(),
      body: new SmsBody({
        ...prev.getBody().toJS(),
        textBody: body,
      }),
    })), [setData]);

  const setMessageType = React.useCallback((messageType: SmsMessageType) =>
    setData((prev) => new CreateSmsTemplateRequest({
      ...prev.toJS(),
      messageType,
    })), [setData]);

  const setApplicationId = React.useCallback((applicationId: string) =>
    setData((prev) => new CreateSmsTemplateRequest({
      ...prev.toJS(),
      applicationId,
    })), [setData]);

  const validTemplate = React.useMemo(() =>
    !isEmptyString(data.getDescription()) && !isEmptyString(data.getTextBody()), [data]);

  const disabledSteps = React.useMemo(() => validTemplate ? [] : DEFAULT_STEPS, [validTemplate]);

  const mapStepToLabel = (step: SmsTemplateWizardStep) => DEFAULT_STEP_LABELS[step] || null;

  const onChangeCurrentStep = React.useCallback((step) => editMode ? resetEdit() : reset()
    , [editMode, resetEdit, reset]);

  const templateView = React.useMemo(() => (
    <SmsTemplateInfo
      languages={showSuccessView ? languages.sort() : [EmailTemplateLanguage.EN_US]}
      setDescription={setDescription}
      setLanguage={setLanguage}
      setMessageType={setMessageType}
      setBody={setBody}
      description={data.getDescription()}
      body={data.getTextBody()}
      language={data.getLanguage() as EmailTemplateLanguage}
      messageType={data.getMessageType() as SmsMessageType}
    />
  ), [
    showSuccessView,
    languages,
    setDescription,
    setLanguage,
    setMessageType,
    setBody,
    data,
  ]);

  const [{ applications: items, ...otherApplicationsModel }, { ...otherActions }] =
    useGetApplicationsRegional();

  const applicationView = React.useMemo(() => (
    <ApplicationView
      {...otherApplicationsModel}
      {...otherActions}
      items={items}
      applicationId={data.getApplicationId()}
      setSelectedApplicationId={(applicationId: string) => setApplicationId(applicationId)}
    />
  ), [
    otherApplicationsModel,
    otherActions,
    items,
    data,
    setApplicationId,
  ]);

  const reviewView = React.useMemo(() => (
    <ReviewView
      data={data}
    />
  ), [data]);

  const mapStepToView = React.useCallback((step: SmsTemplateWizardStep) => {
    switch (step) {
      case SmsTemplateWizardStep.TEMPLATE:
        return templateView;
      case SmsTemplateWizardStep.APPLICATION:
        return applicationView;
      case SmsTemplateWizardStep.REVIEW:
        return reviewView;
      default:
        return null;
    }
  }, [
    templateView,
    applicationView,
    reviewView,
  ]);

  const onSuccess = React.useCallback(() => showTemplateDetails(editMode ? editTemplateId : templateId)
    , [showTemplateDetails, editMode, editTemplateId, templateId]);

  const errorTitle = React.useMemo(() => editMode ? "Edit SMS Template Failed"
    : "Create SMS Template Failed", [editMode]);
  const saveButtonLabel = React.useMemo(() => editMode ? "Edit Template" : "Create Template", [editMode]);
  const snackbarId = React.useMemo(() => editMode ? "edit-sms-template" : "create-sms-template", [editMode]);

  const save = React.useCallback(() => editMode ? editTemplate() : createTemplate()
    , [editMode, editTemplate, createTemplate]);
  const model = React.useMemo(() => editMode ? editModel : otherModel, [editMode, editModel, otherModel]);

  React.useEffect(() => {
    if (editMode) {
      setData(new CreateSmsTemplateRequest({
        ...smsTemplate.toJS()
      }));
    }
  }, [smsTemplate]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      {...model}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      saveButtonLabel={saveButtonLabel}
      currentState={data}
      defaultState={defaultData}
      onSuccess={onSuccess}
      nextStepButtonDisabled={!validTemplate}
      saveButtonDisabled={!validTemplate}
      steps={DEFAULT_STEPS}
      disabledSteps={disabledSteps}
      mapStepToLabel={mapStepToLabel}
      mapStepToView={mapStepToView}
      save={save}
      onChangeCurrentStep={onChangeCurrentStep}
    />
  );
};

export default CreateSmsTemplateContainer;
