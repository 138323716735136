import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { CircularProgress } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface CostHeaderCardModel {
  title: string;
  subtitle: string;
  showLoadingIndicator: boolean;
}

export interface CostHeaderCardActions {
}

type Model = CostHeaderCardModel;
type Actions = CostHeaderCardActions;
type Props = WithStyles<typeof styles> & Model & Actions & {};

export const CostHeaderCard = withStyles(styles)((props: Props) => {

  const {
    classes,
    title,
    subtitle,
    showLoadingIndicator,
  } = props;

  const formatTitle = (input: string) => {
    return input
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase());
  };

  return (

    <div className={classnames("headerCard", classes.headerCard)}>
      <div className={classnames("headerCardTitle", classes.headerCardTitle)}>
        {formatTitle(title)}
      </div>
      <div className={classnames("headerCardSubTitle", classes.headerCardSubTitle)}>
        {showLoadingIndicator ? <CircularProgress size={20} /> : subtitle}
      </div>
    </div>
  );
});

export default CostHeaderCard;
