import React from "react";
import { useRouteMatch } from "react-router-dom";
import { QualityReportsTab } from "./components/QualityReportsTabs";
import {
  COMMON_SERVICES_QUALITY_REPORTS_PATH,
  DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH,
  DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  SECURITY_SERVICES_QUALITY_REPORTS_PATH,
  SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH,
} from "./constants";

const DEFAULT_SELECTED_TAB = QualityReportsTab.SECURITY_SERVICES;

function useSelectedTab(): QualityReportsTab {

  const selfServiceToolsQualityReportsMatch = useRouteMatch(SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH);
  const dataAndAnalyticsQualityReportsMatch = useRouteMatch(DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH);
  const commonServicesQualityReportsMatch = useRouteMatch(COMMON_SERVICES_QUALITY_REPORTS_PATH);
  const deviceManagementQualityReportsMatch = useRouteMatch(DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH);
  const securityServicesQualityReportsMatch = useRouteMatch(SECURITY_SERVICES_QUALITY_REPORTS_PATH);

  return React.useMemo<QualityReportsTab>(() => {
    // Match does not have to be exact because this logic is concerned with the active platform domain
    if (selfServiceToolsQualityReportsMatch) {
      return QualityReportsTab.SELF_SERVICE_TOOLS;
    } else if (dataAndAnalyticsQualityReportsMatch) {
      return QualityReportsTab.DATA_AND_ANALYTICS;
    } else if (commonServicesQualityReportsMatch) {
      return QualityReportsTab.COMMON_SERVICES;
    } else if (deviceManagementQualityReportsMatch) {
      return QualityReportsTab.DEVICE_MANAGEMENT;
    } else if (securityServicesQualityReportsMatch) {
      return QualityReportsTab.SECURITY_SERVICES;
    } else {
      return DEFAULT_SELECTED_TAB;
    }
  }, [
    selfServiceToolsQualityReportsMatch,
    dataAndAnalyticsQualityReportsMatch,
    commonServicesQualityReportsMatch,
    deviceManagementQualityReportsMatch,
    securityServicesQualityReportsMatch,
  ]);
}

export default useSelectedTab;
