import React from "react";
import CostReports, {
  CostReportsActions,
  CostReportsModel,
} from "../components/CostReports";
import {
  useGetCostReportingGroupedMetrics,
  useGetCostReportingMetrics
} from "@hooks/costReporting";
import {
  CostReportingMetricFilter,
  CostReportingPeriodType,
  CostReportingResourceType,
  CostReportingUnit
} from "@data";

interface ContainerModel extends CostReportsModel {
}

interface ContainerActions extends CostReportsActions {
}

type Model = ContainerModel;
type Actions = ContainerActions;
type Props = Model & Actions;

const CostReportsContainer = (props: Props) => {

  const [showFilters, setShowFilters] = React.useState(true);
  const [graphUnit, setGraphUnit] = React.useState(CostReportingUnit.COST);
  const [resourceType, setResourceType] = React.useState(CostReportingResourceType.USER);
  const [metricFilter, setMetricFilter] = React.useState(CostReportingMetricFilter.DOMAIN_TYPE);
  const [periodType, setPeriodType] = React.useState(CostReportingPeriodType.MONTHLY);

  const [metricModel, metricActions] = useGetCostReportingMetrics({ periodType });

  const { refresh: refreshMetrics } = metricActions;

  const [{
    groupedMetrics,
    showLoadingIndicator: loadingGroupedMetrics,
  }, { refresh: refreshGroupedMetrics }
  ] = useGetCostReportingGroupedMetrics({ periodType, metricFilter, resourceType });

  React.useEffect(() => {
    refreshMetrics();
    refreshGroupedMetrics();
  }, [refreshMetrics, refreshGroupedMetrics, periodType, metricFilter, resourceType]);

  return (
    <CostReports
      showFilters={showFilters}
      graphUnit={graphUnit}
      periodType={periodType}
      resourceType={resourceType}
      metricFilter={metricFilter}
      metricModel={metricModel}
      metricActions={metricActions}
      groupedMetrics={groupedMetrics}
      loadingGroupedMetrics={loadingGroupedMetrics}
      setShowFilters={setShowFilters}
      setGraphUnit={setGraphUnit}
      setPeriodType={setPeriodType}
      setResourceType={setResourceType}
      setMetricFilter={setMetricFilter}
    />
  );
};

export default CostReportsContainer;
