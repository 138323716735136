import React from "react";
import { useHistory } from "react-router-dom";
import { getPathToSmsTemplate } from "@modules/smsTemplateDetails/helpers";
import CreateSmsTemplate from "./containers/CreateSmsTemplate";

export function CreateSmsTemplateView() {

  const history = useHistory();

  const cancel = React.useCallback(() => history.goBack(), [history]);

  const showTemplateDetails = React.useCallback((templateId: string) =>
    history.replace(getPathToSmsTemplate(templateId)), [history]);

  return (
    <CreateSmsTemplate
      cancel={cancel}
      showTemplateDetails={showTemplateDetails}
    />
  );
}

export default CreateSmsTemplateView;
