import { Record } from "immutable";
import { equalsIgnoreCase, getStringValue } from "@util";
import { SmsBody, SmsBodyAttributes } from "./SmsBody";
import { EmailTemplateLanguage, SmsMessageType } from "@data";

export interface SmsTemplateAttributes {
  id: string;
  messageType: SmsMessageType;
  language: EmailTemplateLanguage;
  body: SmsBodyAttributes;
  applicationId?: string;
}

export class SmsTemplate extends Record({
  id: "",
  messageType: "",
  language: EmailTemplateLanguage.EN_US,
  body: SmsBody.EMPTY.toJS(),
  applicationId: "",
}) implements SmsTemplateAttributes {

  public static EMPTY: SmsTemplate = new SmsTemplate();

  public readonly id: string;
  public readonly messageType: SmsMessageType;
  public readonly language: EmailTemplateLanguage;
  public readonly body: SmsBodyAttributes;
  public readonly applicationId: string;

  public equals(other: SmsTemplate): boolean {
    return equalsIgnoreCase(this.getId(), other.getId()) &&
      equalsIgnoreCase(this.getMessageType(), other.getMessageType()) &&
      equalsIgnoreCase(this.getLanguage(), other.getLanguage()) &&
      equalsIgnoreCase(this.getApplicationId(), other.getApplicationId()) &&
      this.getBody().equals(other.getBody());
  }

  public getId(): string {
    return getStringValue(this.id);
  }

  public getMessageType(): string {
    return getStringValue(this.messageType);
  }

  public getLanguage(): string {
    return getStringValue(this.language);
  }

  public getBody(): SmsBody {
    return new SmsBody(this.body);
  }

  public getTextBody(): string {
    return this.getBody().getTextBody();
  }

  public getDescription(): string {
    return this.getBody().getDescription();
  }

  public getApplicationId(): string {
    return getStringValue(this.applicationId);
  }
}

export default SmsTemplate;
