import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { reviewView as styles } from "./styles";
import { SummaryView } from "@components";
import { CreateSmsTemplateRequest, SummaryViewData } from "@data";

export interface Model {
  data: CreateSmsTemplateRequest;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    data,
  } = props;

  const summaryItems = React.useMemo(() => [
    new SummaryViewData({
      className: "language",
      name: "Language",
      value: data.getLanguage(),
    }),
    new SummaryViewData({
      className: "messageType",
      name: "Message Type",
      value: data.getMessageType(),
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: data.getDescription(),
    }),
    new SummaryViewData({
      className: "textBody",
      name: "Text Body",
      value: data.getTextBody(),
    }),
    new SummaryViewData({
      className: "applicationId",
      name: "Application ID",
      value: data.getApplicationId(),
    }),
  ], [data]);

  return (
    <SummaryView
      title="Review SMS Template"
      className={classnames("reviewView", classes.container)}
      items={summaryItems}
    />
  );
});

export default ReviewView;
