import { Record } from "immutable";
import { getStringValue } from "@util";

export interface CostReportingGroupedMetricAttributes {
  name: string;
  date: string;
  costValue: number;
  countValue: number;
}

export class CostReportingGroupedMetric extends Record({
  name: "",
  date: "",
  costValue: 0.0,
  countValue: 0,
}) implements CostReportingGroupedMetricAttributes {

  public static EMPTY: CostReportingGroupedMetric = new CostReportingGroupedMetric();

  public readonly name: string;
  public readonly date: string;
  public readonly costValue: number;
  public readonly countValue: number;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getDate(): string {
    return getStringValue(this.date);
  }

  public getCostValue(): number {
    return this.costValue;
  }

  public getCountValue(): number {
    return this.countValue;
  }
}

export default CostReportingGroupedMetric;
