import React from "react";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import DownloadFile from "@components/download-file";
import { Tooltip } from "@components";
import DownloadIcon from "@material-ui/icons/GetApp";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import JSZip, { JSZipObject, loadAsync } from "jszip";
import Typography from "@material-ui/core/Typography";
import { clickHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ProjectViewListItemView, {
  ProjectViewListItem,
  ProjectViewListItemType,
} from "./ProjectViewListItemView";
import ProjectViewActionsMenu from "./ProjectViewActionsMenu";
import styles from "./styles";

const readZipFile = (zipFile: JSZipObject): Promise<string> =>
  zipFile.async("string").catch(() => "");

const mapZipFileToProjectViewListItem = async (zipFile: JSZipObject,
                                               zip: JSZip | null = null): Promise<ProjectViewListItem | null> => {

  if (zip === null) {
    return Promise.resolve(null);
  }

  const id = zipFile.name;

  const name = id.split("/").filter(it => !isEmptyString(it)).pop() || "";

  if (isEmptyString(name)) {
    return Promise.resolve(null);
  }

  if (!zipFile.dir) {
    return Promise.resolve<ProjectViewListItem>({
      id,
      name,
      type: ProjectViewListItemType.FILE,
      code: await readZipFile(zipFile),
    });
  }

  return Promise.resolve<ProjectViewListItem>({
    id,
    name,
    type: ProjectViewListItemType.FOLDER,
    collapsed: true,
    children: await Promise.all(zip.filter((relativePath, file) => {
      if (file.name === id || relativePath.indexOf(id) !== 0 || file.name.indexOf(".DS_Store") >= 0) {
        return false;
      }
      return (relativePath.split(id).pop() || "").split("/").filter(it => !isEmptyString(it)).length === 1;
    }).map(async it => await mapZipFileToProjectViewListItem(it, zip))
      .filter(async promise => {
        const result = await promise;
        return result !== null;
      })).then(result => Array.prototype.concat.apply([], result)),
  });
};

const mapZipToProjectViewListItems = async (zip: JSZip | null): Promise<ProjectViewListItem[]> => {
  if (zip === null) {
    return Promise.resolve([] as ProjectViewListItem[]);
  }
  const fileRegistry = zip.files;
  return Object.keys(fileRegistry)
    .filter(key => key.indexOf(".DS_Store") === -1)
    .reduce(async (combined: Promise<ProjectViewListItem[]>, id: string) => {
      if (id.split("/").filter(it => !isEmptyString(it)).length > 1) {
        return combined;
      }
      const zipFile = fileRegistry[id];
      const listItem = await mapZipFileToProjectViewListItem(zipFile, zip);
      if (listItem === null) {
        return combined;
      }
      const data = await combined;
      return Promise.resolve(data.concat([listItem]));
    }, Promise.resolve([] as ProjectViewListItem[]));
};

export interface ProjectViewModel {
  className?: string;
  title?: string;
  workloadName?: string;
  files?: ProjectViewListItem[];
  zipFile?: any;
  entryFileName?: string;
  loading?: boolean;
  codeUploadDisabled?: boolean;
  showDownloadErrorView?: boolean;
  emptyViewTitle?: string;
  downloadErrorMessage?: string;
  emptyViewSubtitle?: string;
}

export interface ProjectViewActions {
  collapseFolder?: (id: string) => void;
  expandFolder?: (id: string) => void;
  setSelectedFile?: (id: string) => void;
  renameFile?: (id: string, updatedName: string) => void;
  deleteFile?: (id: string) => void;
  addFile?: (id: string, name: string, parentId?: string) => void;
  addFolder?: (id: string, name: string, parentId?: string) => void;
  setFiles?: (files: ProjectViewListItem[]) => void;
  reloadCode?: () => void;
  setInitialFiles?: (files: ProjectViewListItem[]) => void;
}

type Model = ProjectViewModel;
type Actions = ProjectViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ProjectView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Files",
    emptyViewTitle = "No Files Found",
    workloadName,
    emptyViewSubtitle = "Upload code to start editing",
    downloadErrorMessage,
    files = [],
    entryFileName = "",
    loading = false,
    showDownloadErrorView,
    codeUploadDisabled,
    zipFile,
    collapseFolder,
    expandFolder = noop,
    setSelectedFile = noop,
    renameFile,
    deleteFile,
    addFile = noop,
    addFolder = noop,
    setFiles = noop,
    reloadCode = noop,
    setInitialFiles = noop,
    children,
  } = props;

  const [downloadLink, setDownloadLink] = React.useState("");

  const [unzipping, setUnzipping] = React.useState(false);

  const [downloading, setDownloading] = React.useState(false);

  const showEmptyView = React.useMemo(() => files.length === 0, [files]);

  const downloadFiles = clickHandler(React.useCallback(() => {
    const zip = new JSZip();
    const iterate = (items: ProjectViewListItem[], relativePath = "") => {
      items.forEach(item => {
        const { name, type, code = "", children: fileContents = [] } = item;
        if (type === ProjectViewListItemType.FILE) {
          zip.file(`${relativePath}${name}`, code);
        } else if (type === ProjectViewListItemType.FOLDER) {
          iterate(fileContents, `${relativePath}/${name}/`);
        }
      });
    };
    iterate(files);
    zip.generateAsync({ type: "base64" }).then(base64 => {
      setDownloadLink(`data:application/zip;base64,${base64}`);
    });
  }, [files, setDownloadLink]));

  const findFileById = (items: ProjectViewListItem[], fileName: string): string | null => {
    for (const file of items) {
      if (file.type === ProjectViewListItemType.FILE && file.name === fileName) {
        return file.id;
      } else if (file.type === ProjectViewListItemType.FOLDER) {
        const found = findFileById(file.children || [], fileName);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const clearToolTip = React.useMemo(() =>
  zipFile !== null ? "Restore last saved version" : "Delete All", [zipFile]);

  const sortedFiles = React.useMemo(() => {
    return files.slice().sort((a, b) => {
      if (a.type === ProjectViewListItemType.FOLDER && b.type !== ProjectViewListItemType.FOLDER) {
        return -1;
      }
      if (a.type !== ProjectViewListItemType.FOLDER && b.type === ProjectViewListItemType.FOLDER) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
  }, [files]);

  const loadZipFile = React.useCallback(() => {
    let ignore = false;

    if (zipFile) {
      loadAsync(zipFile)
        .then(zip => {
          if (!ignore) {
            setUnzipping(true);
            mapZipToProjectViewListItems(zip)
              .then(items => {
                if (!ignore) {
                  setFiles(items);
                  setUnzipping(false);
                }
              }, err => {
                if (!ignore) {
                  console.error("Failed to decompress zip error: " + err.message, err);
                  setUnzipping(false);
                }
              });
          }
        }, error => {
          if (!ignore) {
            console.error("Load Zip File Error: " + error.message, error);
          }
        });
    }

    return () => {
      ignore = true;
    };
  }, [zipFile, setUnzipping, setFiles]);

  const clearAll = clickHandler(React.useCallback(() => {
    if (zipFile === null) {
      setFiles([]);
      setInitialFiles([]);
    } else {
      setFiles([]);
      setDownloading(true);
      reloadCode();
    }
  }, [setFiles, zipFile, reloadCode, setDownloading, setInitialFiles]));

  React.useEffect(() => {
    loadZipFile();

  }, [loadZipFile, zipFile]);

  React.useEffect(() => {
    if ((loading || unzipping) && showEmptyView) {
      setDownloading(true);
    }
    if (!showEmptyView) {
      setDownloading(false);
    }
  }, [loading, unzipping, showEmptyView]);

  React.useEffect(() => {
    if (!showEmptyView && zipFile.size < 500) {
      const fileId = findFileById(files, entryFileName);
      if (fileId) {
        setSelectedFile(fileId);
      }
    }
  }, [unzipping, showEmptyView]);

  return (
    <div className={classnames("projectView", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)}>
          {title}
        </Typography>
        {!showEmptyView && (
          <React.Fragment>
            <Tooltip
              title={clearToolTip}
              placement="bottom"
            >
              <IconButton
                className={classnames("clearAllButton", classes.iconButton)}
                size="small"
                onClick={clearAll}
              >
                <ClearIcon className={classnames("clearIcon", classes.icon)} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={"Download Editor Code"}
              placement="bottom"
            >
              <IconButton
                className={classnames("downloadIconButton", classes.iconButton)}
                size="small"
                onClick={downloadFiles}
              >
                <DownloadIcon className={classnames("downloadIcon", classes.icon)} />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
        <ProjectViewActionsMenu
          className={classnames("actionsMenu", classes.actionsMenu)}
          actionDisabled={downloading || codeUploadDisabled}
          addFile={() => addFile(uuid(), "")}
          addFolder={() => addFolder(uuid(), "")}
        />
      </div>
      {downloading && !showDownloadErrorView && (
        <Typography className={classnames("unzippingTitle", classes.unzippingTitle)}>
          Loading Project Files...
        </Typography>
      )}
      {!downloading && showEmptyView && !showDownloadErrorView && (
        <div className={classnames("emptyView", classes.emptyView)}>
          <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
            {emptyViewTitle}
          </Typography>
          <Typography className={classnames("emptyViewSubtitle", classes.emptyViewSubtitle)}>
            {emptyViewSubtitle}
          </Typography>
        </div>
      )}
      {showDownloadErrorView && (
        <div className={classnames("downloadErrorView", classes.emptyView)}>
          <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
            Failed to download Code
          </Typography>
          <Typography className={classnames("downloadErrorMessage", classes.emptyViewSubtitle)}>
            {downloadErrorMessage}
          </Typography>
        </div>
      )}
      {!showEmptyView && (
        <ul className={classnames("list", classes.list)}>
          {sortedFiles.map(({ id, name, ...file }) => (
            <ProjectViewListItemView
              key={id}
              className={classnames("listItem", classes.listItem)}
              file={{ id, name, ...file }}
              editMode={isEmptyString(name)}
              codeEditingDisabled={codeUploadDisabled}
              collapseFolder={collapseFolder}
              expandFolder={expandFolder}
              setSelectedFile={setSelectedFile}
              renameFile={renameFile}
              deleteFile={deleteFile}
              addFile={addFile}
              addFolder={addFolder}
            />
          ))}
        </ul>
      )}
      {downloadLink && (
        <DownloadFile
          href={downloadLink}
          fileName={`${workloadName}.zip`}
          downloadFinished={() => setDownloadLink("")}
        />
      )}
      {children}
    </div>
  );
});

export default ProjectView;