import { Module } from "@data";
import { createModule } from "@modules/base";
import { getCreateSmsTemplatePath, getEditSmsTemplatePath } from "./helpers";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import CreateSchemaView from "./CreateSmsTemplateView";
import EditSchemaView from "./EditSmsTemplateView";
import { isSmsTemplateEnabled } from "@util";

export const createSmsTemplateModule: Module = createModule({
  id: "create_sms_template",
  path: getCreateSmsTemplatePath(),
  exactPath: true,
  strictPath: true,
  name: "Create SMS Template",
  title: "Create New SMS Template",
  className: "createSmsTemplateModule",
  primaryColor,
  icon,
  view: CreateSchemaView,
  disabled: !isSmsTemplateEnabled(),
});

export const editSmsTemplateModule: Module = createModule({
  id: "edit_sms_template",
  path: getEditSmsTemplatePath(":templateId"),
  exactPath: true,
  strictPath: true,
  name: "Edit SMS Template",
  title: "Edit SMS Template",
  className: "editSmsTemplateModule",
  primaryColor,
  icon,
  view: EditSchemaView,
  disabled: !isSmsTemplateEnabled(),
});

export default createSmsTemplateModule;
