import React from "react";
import styles from "./styles";
import classnames from "classnames";
import WorkloadPolicy from "./WorkloadPolicy";
import TagsIcon from "@material-ui/icons/Label";
import DnsNameIcon from "@material-ui/icons/CheckCircle";
import { JsonEditor as SqlEditor } from "@components/json-editor";
import { noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  ChipView,
  DataSetsList,
  DataSetsListColumn,
  DataSetsListItemData,
  SummaryView,
  WorkloadCompletionMetadata,
} from "@components";
import {
  DEFAULT_WORKLOAD_TYPE_LABELS,
  Policy,
  SummaryViewData,
  WorkloadType,
  WorkloadTypeLabels,
} from "@data";
import CreateWorkloadRequest, {
  DEFAULT_WORKLOAD_RUNTIME_OPTION_LABELS,
  WorkloadRuntimeOption,
  WorkloadRuntimeOptionLabels,
} from "@data/CreateWorkloadRequest";

export interface Model {
  accountId: string;
  data?: CreateWorkloadRequest;
  typeLabels?: WorkloadTypeLabels;
  runtimeOptionLabels?: WorkloadRuntimeOptionLabels;
  selectedSources?: DataSetsListItemData[];
  selectedOutputs?: DataSetsListItemData[];
}

export interface Actions {
  mapRuntimeOptionToLabel?: (type: WorkloadRuntimeOption) => React.ReactNode | string | null;
  mapWorkloadTypeToLabel?: (type: WorkloadType) => React.ReactNode | string | null;
  showDataSetDetails?: (dataSet: DataSetsListItemData) => void;
  showDataSetDetailsInNewTab: (dataSet: DataSetsListItemData) => string;
  setPolicy?: (policy: Policy) => void;
  removePolicy?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ReviewView = withStyles(styles)((props: Props) => {

  const {
    classes,
    accountId,
    selectedSources = [],
    selectedOutputs = [],
    showDataSetDetails = noop,
    showDataSetDetailsInNewTab = () => "",
    data = CreateWorkloadRequest.EMPTY,
    typeLabels = DEFAULT_WORKLOAD_TYPE_LABELS,
    runtimeOptionLabels = DEFAULT_WORKLOAD_RUNTIME_OPTION_LABELS,
    mapWorkloadTypeToLabel = React.useCallback((type: WorkloadType) =>
      typeLabels[type] || null, [typeLabels]),
    mapRuntimeOptionToLabel = React.useCallback((type: WorkloadRuntimeOption) =>
      runtimeOptionLabels[type] || null, [runtimeOptionLabels]),
    setPolicy,
    removePolicy,
  } = props;

  const summaryItems = React.useMemo(() => [
    new SummaryViewData({
      className: "workloadType",
      name: "Type",
      value: mapWorkloadTypeToLabel(data.getType()),
    }),
    new SummaryViewData({
      className: "workloadName",
      name: "Name",
      value: data.name,
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: data.description,
    }),
    new SummaryViewData({
      className: "runtimeOption",
      name: "Runtime",
      value: mapRuntimeOptionToLabel(data.runtime),
      hidden: data.isQueryType(),
    }),
    new SummaryViewData({
      className: "entryPoint",
      name: "Entry Point",
      value: data.getEntryPoint(),
      hidden: data.isQueryType(),
    }),
    new SummaryViewData({
      className: "timeout",
      name: "Timeout",
      value: data.getTimeoutAsString(),
      hidden: data.isQueryType(),
    }),
    new SummaryViewData({
      className: "memory",
      name: "Memory",
      value: data.getMemoryAsString(),
      hidden: data.isQueryType(),
    }),
    new SummaryViewData({
      className: "overwrite",
      name: "Overwrite",
      value: data.isOverwrite().toString().toUpperCase(),
      hidden: data.isFunctionType(),
    }),
    new SummaryViewData({
      className: "upload",
      name: "Upload",
      value: data.isUpload().toString().toUpperCase(),
      hidden: data.isFunctionType() || data.isInsertQuery(),
    }),
    new SummaryViewData({
      className: "databaseName",
      name: "Database",
      value: data.getDatabaseName(),
      hidden: data.isFunctionType(),
    }),
    new SummaryViewData({
      className: "tableName",
      name: "Table",
      value: data.getTableName(),
      hidden: data.isFunctionType(),
    }),
  ], [data, mapWorkloadTypeToLabel, mapRuntimeOptionToLabel]);

  const showTags = data.getTags().length > 0;
  const showDNS = data.getWhitelistedDNSNames().length > 0;
  const showMetadata = React.useMemo(() => data.getWorkloadCompletionMetadataActions().length > 0 ||
    data.getWorkloadCompletionMetadataVariableSchemas().length > 0, [data]);

  return (
    <div className={classnames("reviewView", classes.container)}>
      <div className={classnames("summaryViewContainer", classes.summaryViewContainer)}>
        <SummaryView
          className={classnames("summaryView", classes.summaryView)}
          items={summaryItems}
          title="Review Workload"
        />
        <WorkloadPolicy
          className={classnames("policyView", classes.policyView)}
          accountId={accountId}
          workloadName={data.getName()}
          {...data.hasPolicy() ? ({ policy: data.getPolicy() }) : ({})}
          setPolicy={setPolicy}
          removePolicy={removePolicy}
        />
      </div>
      {data.isQueryType() && (
        <React.Fragment>
          <label className={classnames("title", classes.title)}>
            SQL Query
          </label>
          <SqlEditor
            className={classnames("sqlEditor", classes.sqlEditor)}
            mode="mysql"
            name="querySqlEditor"
            height="200px"
            showPrintMargin={false}
            wrapEnabled={true}
            marginTop={false}
            readOnly={true}
            json={data.getQuery()}
          />
        </React.Fragment>
      )}
      <div className={classnames("dataSetContainer", classes.dataSetContainer)}>
        {selectedSources.length > 0 && (
          <div className={classnames("dataSetInputContainer", classes.dataSetInputContainer)}>
            <div className={classnames("dataSetControls", classes.dataSetControls)}>
              <label className={classnames("title", classes.title)}>
                Data Set Inputs
              </label>
            </div>
            <DataSetsList
              className={classnames("dataSetInputList", classes.dataListView)}
              noResultsLabel="No Data Set inputs selected for this workload"
              selectable={false}
              showSearch={false}
              selectAllDisabled={true}
              tableLayoutFixed={false}
              columns={[ DataSetsListColumn.NAME ]}
              items={selectedSources}
              onClickShowMoreInfoInNewTab={showDataSetDetailsInNewTab}
              showMoreInfoLabel="Data Set Details"
              onClickItem={showDataSetDetails}
            />
          </div>
        )}
        {selectedOutputs.length > 0 && (
          <div className={classnames("dataSetOutputContainer", classes.dataSetOutputContainer)}>
            <div className={classnames("dataSetControls", classes.dataSetControls)}>
              <label className={classnames("title", classes.title)}>
                Data Set Outputs
              </label>
            </div>
            <DataSetsList
              className={classnames("dataSetOutputList", classes.dataListView)}
              noResultsLabel="No Data Set outputs selected for this workload"
              selectable={false}
              showSearch={false}
              selectAllDisabled={true}
              tableLayoutFixed={false}
              columns={[ DataSetsListColumn.NAME ]}
              items={selectedOutputs}
              onClickShowMoreInfoInNewTab={showDataSetDetailsInNewTab}
              showMoreInfoLabel="Data Set Details"
              onClickItem={showDataSetDetails}
            />
          </div>
        )}
      </div>
      {showMetadata && (
        <WorkloadCompletionMetadata
          className={classnames("completionMetadata", classes.completionMetadata)}
          actions={data.getWorkloadCompletionMetadataActions()}
          variables={data.getWorkloadCompletionMetadataVariableSchemas()}
        />
      )}
      {showTags && (
        <ChipView
          title={"Tags"}
          items={data.getTags()}
          className={classnames("chipView", classes.chipView)}
          icon={TagsIcon}
        />
      )}
      {showDNS && (
        <ChipView
          title={"Accessible DNS Names"}
          items={data.getWhitelistedDNSNames()}
          className={classnames("chipView", classes.chipView)}
          icon={DnsNameIcon}
        />
      )}
    </div>
  );
});

export default ReviewView;
