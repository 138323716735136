import React from "react";
import { isQualityReportsListViewEnabled } from "@util";
import QualityReports from "./containers/QualityReports";
import { Redirect, Route, Switch } from "react-router-dom";
import { QualityReportsTab } from "./components/QualityReportsTabs";
import { getPathToQualityReport } from "@modules/qualityReportDetails/helpers";
import {
  BASE_URL,
  COMMON_SERVICES_QUALITY_REPORTS_PATH,
  DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  SECURITY_SERVICES_QUALITY_REPORTS_PATH,
} from "./constants";
import QualityReport, { QualityReportPlatformDomainId } from "@data/QualityReport";
import useSelectedTab from "./useSelectedTab";

const LIST_VIEW_ENABLED = isQualityReportsListViewEnabled();

const LATEST_COMMON_SERVICES_REPORT = new QualityReport({
  version: "latest",
  platformDomain: {
    id: QualityReportPlatformDomainId.COMMON_SERVICES,
  },
  group: {
    id: "data-modeling-schema-management",
  },
});

const LATEST_DEVICE_MANAGEMENT_REPORT = new QualityReport({
  version: "latest",
  platformDomain: {
    id: QualityReportPlatformDomainId.DEVICE_MANAGEMENT,
  },
  group: {
    id: "device-management-core",
  },
});

const LATEST_SECURITY_SERVICES_USER_IDM_REPORT = new QualityReport({
  version: "latest",
  platformDomain: {
    id: QualityReportPlatformDomainId.SECURITY_SERVICES,
  },
  group: {
    id: "user-idm",
  },
});

export interface QualityReportsViewProps {
  listViewEnabled?: boolean;
}

export function QualityReportsView(props: QualityReportsViewProps) {

  const { listViewEnabled = LIST_VIEW_ENABLED } = props;

  const selectedTab = useSelectedTab();

  const platformDomainId = React.useMemo(() => {
    switch (selectedTab) {
      case QualityReportsTab.DATA_AND_ANALYTICS:
        return QualityReportPlatformDomainId.DATA_AND_ANALYTICS;
      case QualityReportsTab.COMMON_SERVICES:
        return QualityReportPlatformDomainId.COMMON_SERVICES;
      case QualityReportsTab.DEVICE_MANAGEMENT:
        return QualityReportPlatformDomainId.DEVICE_MANAGEMENT;
      case QualityReportsTab.SECURITY_SERVICES:
        return QualityReportPlatformDomainId.SECURITY_SERVICES;
      case QualityReportsTab.SELF_SERVICE_TOOLS:
        return QualityReportPlatformDomainId.SELF_SERVICE_TOOLS;
      default:
        return QualityReportPlatformDomainId.NONE;
    }
  }, [selectedTab]);

  if (listViewEnabled) {
    return (
      <Switch>
        <Route path={BASE_URL} exact={true} strict={false}>
          <Redirect to={SECURITY_SERVICES_QUALITY_REPORTS_PATH}/>
        </Route>
        <Route>
          <QualityReports
            selectedTab={selectedTab}
            platformDomainId={platformDomainId}
          />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={BASE_URL} exact={true} strict={false}>
        <Redirect to={SECURITY_SERVICES_QUALITY_REPORTS_PATH}/>
      </Route>
      <Route path={COMMON_SERVICES_QUALITY_REPORTS_PATH}>
        <Redirect to={getPathToQualityReport(LATEST_COMMON_SERVICES_REPORT)}/>
      </Route>
      <Route path={DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH}>
        <Redirect to={getPathToQualityReport(LATEST_DEVICE_MANAGEMENT_REPORT)}/>
      </Route>
      <Route>
        <Redirect to={getPathToQualityReport(LATEST_SECURITY_SERVICES_USER_IDM_REPORT)}/>
      </Route>
    </Switch>
  );
}

export default QualityReportsView;
