import { Module } from "@data";
import { createModule } from "@base/createModule";
import { PrimaryIcon as icon } from "@modules/smsTemplates/styles";
import { MODULE_ID, MODULE_PATH, RouteParams } from "./constants";
import view from "./SmsTemplateDetailsView";
import { isSmsTemplateEnabled } from "@util";

export const smsTemplateDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: false,
  showBreadcrumbs: true,
  name: "SMS Template Details",
  title: "SMS Template Details",
  className: "smsTemplateDetailsModule",
  icon,
  view,
  disabled: !isSmsTemplateEnabled(),
});

export default smsTemplateDetailsModule;
