import { Module } from "@data";
import { Selector } from "@base";
import { equalsIgnoreCase } from "@util";
import { createSelector } from "reselect";
import { getAccountEnabledModules } from "./selectors";
import {
  QueryData,
  QuerySchedules,
  SupersetDashboards,
  UserDatabase,
} from "@modules/portlet/module";
import dataSetsModule from "@modules/dataSets/module";
import trainingCertificateModule from "@modules/trainingCertificate/module";
import dataSetRequestsModule from "@modules/dataSetRequests/module";
import dataAccessRequestsModule from "@modules/dataAccessRequests/module";
import dataApprovalRequestsModule from "@modules/dataApprovalRequests/module";
import DashboardAccessControlModule from "@modules/dashboards/module";
import dataLakeBrowserModule from "@modules/dataLakeBrowser/module";
import dataWorkloadsModule from "@modules/dataWorkloads/module";
import namespacesModule from "@modules/namespaces/module";
import schemasModule from "@modules/schemas/module";
import deviceTypesModule from "@modules/deviceTypes/module";
import deviceEnrollmentModule from "@modules/deviceEnrollment/module";
import deviceModule from "@modules/devices/module";
import digitalShadowTypesModule from "@modules/digitalShadowTypes/module";
import digitalShadowLayoutsModule from "@modules/digitalShadowLayouts/module";
import policiesModule from "@modules/policies/module";
import managedPoliciesModule from "@modules/managedPolicies/module";
import securityGroupsModule from "@modules/securityGroups/module";
import usersModule from "@modules/users/module";
import emailTemplatesModule from "@modules/emailTemplates/module";
import smsTemplatesModule from "@modules/smsTemplates/module";
import uiPackageModule from "@modules/uiPackage/module";
import profileAttributeDefinitionsModule from "@modules/profileAttributeDefinitions/module";
import federationProvidersModule from "@modules/federationProviders/module";
import securityServicesRegionalModule from "@modules/securityServicesRegional/module";
import applicationsRegionalModule from "@modules/applicationsRegional/module";
import auditEventsModule from "@modules/auditEvents/module";
import certificateAuthoritiesModule from "@modules/certificateAuthorities/module";

const DATA_ANALYTICS_MODULES_DISABLED =
  equalsIgnoreCase("false", process.env.REACT_APP_DATA_ANALYTICS_MODULES_ENABLED);

const filterIds = (ids: string[]) => ({ id }: { id: string }) => ids.indexOf(id) >= 0;

const getEnabledModuleIds: Selector<string[]> = createSelector(
  getAccountEnabledModules, (modules: Module[]) =>
    Array.from(new Set(modules.map(module => module.id))));

const getDataWarehouseModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    UserDatabase,
    QueryData,
    QuerySchedules,
  ]).filter(filterIds(ids)));

const getDataCatalogModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    dataSetsModule,
  ]).filter(filterIds(ids)));

const getDataPrivacyModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    trainingCertificateModule,
  ]).filter(filterIds(ids)));

const getDataGovernanceModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    dataSetRequestsModule,
    dataAccessRequestsModule,
    dataApprovalRequestsModule,
  ]).filter(filterIds(ids)));

const getDataReportingModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    SupersetDashboards,
    DashboardAccessControlModule,
  ]).filter(filterIds(ids)));

const getDataLakeModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    dataLakeBrowserModule,
  ]).filter(filterIds(ids)));

const getDataProcessingModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    dataWorkloadsModule,
  ]).filter(filterIds(ids)));

const getDataAndAnalyticsModules: Selector<{ [key: string]: Module[] }[]> = createSelector(
  [
    getDataWarehouseModules,
    getDataCatalogModules,
    getDataPrivacyModules,
    getDataGovernanceModules,
    getDataReportingModules,
    getDataLakeModules,
    getDataProcessingModules,
  ], (
    dataWarehouseModules: Module[],
    dataCatalogModules: Module[],
    dataPrivacyModules: Module[],
    dataGovernanceModules: Module[],
    dataReportingModules: Module[],
    dataLakeModules: Module[],
    dataProcessingModules: Module[],
  ) => {

    if (DATA_ANALYTICS_MODULES_DISABLED) {
      return [] as { [key: string]: Module[] }[];
    }

    const modules = [] as { [key: string]: Module[] }[];

    if (dataWarehouseModules.length > 0) {
      modules.push({ "Data Warehouse": dataWarehouseModules });
    }

    if (dataCatalogModules.length > 0) {
      modules.push({ "Data Catalog": dataCatalogModules });
    }

    if (dataPrivacyModules.length > 0) {
      modules.push({ "Data Privacy": dataPrivacyModules });
    }

    if (dataGovernanceModules.length > 0) {
      modules.push({ "Data Governance": dataGovernanceModules });
    }

    if (dataReportingModules.length > 0) {
      modules.push({ "Data Reporting": dataReportingModules });
    }

    if (dataLakeModules.length > 0) {
      modules.push({ "Data Lake": dataLakeModules });
    }

    if (dataProcessingModules.length > 0) {
      modules.push({ "Data Processing": dataProcessingModules });
    }

    return modules;
  });

const getCommonServicesModules: Selector<Module[] | { [key: string]: Module[] }[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    namespacesModule,
    schemasModule,
  ]).filter(filterIds(ids)));

const getDigitalShadowModules: Selector<Module[] | { [key: string]: Module[] }[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    digitalShadowTypesModule,
    digitalShadowLayoutsModule,
  ]).filter(filterIds(ids)));

const getDeviceModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    deviceTypesModule,
    deviceEnrollmentModule,
    deviceModule,
  ]).filter(filterIds(ids)));

const getDeviceManagementModules: Selector<{ [key: string]: Module[] }[]> = createSelector(
  [
    getDeviceModules,
    getDigitalShadowModules,
  ], (
    deviceModules: Module[],
    digitalShadowModules: Module[],
  ) => {

    const modules = [] as { [key: string]: Module[] }[];

    if (deviceModules.length > 0) {
      modules.push({ "Device": deviceModules });
    }

    if (digitalShadowModules.length > 0) {
      modules.push({ "Digital Shadow": digitalShadowModules });
    }

    return modules;
  });

const getAuthorizationModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    policiesModule,
    managedPoliciesModule,
    securityGroupsModule,
  ]).filter(filterIds(ids)));

const getUserIdentityManagementModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    usersModule,
    emailTemplatesModule,
    smsTemplatesModule,
    uiPackageModule,
    profileAttributeDefinitionsModule,
    federationProvidersModule,
  ]).filter(filterIds(ids)));

const getServiceIdentityManagementModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    securityServicesRegionalModule,
  ]).filter(filterIds(ids)));

const getApplicationIdentityManagementModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    applicationsRegionalModule,
  ]).filter(filterIds(ids)));

const getAuditModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    auditEventsModule,
  ]).filter(filterIds(ids)));

const getCertificatesModules: Selector<Module[]> = createSelector(
  [getEnabledModuleIds], (ids: string[]) => ([
    certificateAuthoritiesModule,
  ]).filter(filterIds(ids)));

const getSecurityServicesModules: Selector<{ [key: string]: Module[] }[]> = createSelector(
  [
    getAuthorizationModules,
    getUserIdentityManagementModules,
    getServiceIdentityManagementModules,
    getApplicationIdentityManagementModules,
    getAuditModules,
    getCertificatesModules,
  ], (
    authorizationModules: Module[],
    userIdentityManagementModules: Module[],
    serviceIdentityManagementModules: Module[],
    applicationIdentityManagementModules: Module[],
    auditModules: Module[],
    certificatesModules: Module[],
  ) => {

    const modules = [] as { [key: string]: Module[] }[];

    if (authorizationModules.length > 0) {
      modules.push({ "Authorization": authorizationModules });
    }

    if (userIdentityManagementModules.length > 0) {
      modules.push({ "User Identity Management": userIdentityManagementModules });
    }

    if (serviceIdentityManagementModules.length > 0) {
      modules.push({ "Service Identity Management": serviceIdentityManagementModules });
    }

    if (applicationIdentityManagementModules.length > 0) {
      modules.push({ "Application Identity Management": applicationIdentityManagementModules });
    }

    if (auditModules.length > 0) {
      modules.push({ "Audit": auditModules });
    }

    if (certificatesModules.length > 0) {
      modules.push({ "Certificates": certificatesModules });
    }

    return modules;
  });

export const getNavigationModules: Selector<{ [key: string]: (Module | { [key: string]: Module[] })[]; }> =
  createSelector(
    [
      getDataAndAnalyticsModules,
      getCommonServicesModules,
      getDeviceManagementModules,
      getSecurityServicesModules,
    ],
    (
      dataAndAnalyticsModules: (Module | { [key: string]: Module[] })[],
      commonServicesModules: (Module | { [key: string]: Module[] })[],
      deviceManagementModules: (Module | { [key: string]: Module[] })[],
      securityServicesModules: (Module | { [key: string]: Module[] })[],
    ) => {

      const modules = {};

      if (dataAndAnalyticsModules.length > 0) {
        modules["Data & Analytics"] = dataAndAnalyticsModules;
      }

      if (commonServicesModules.length > 0) {
        modules["Common Services"] = commonServicesModules;
      }

      if (deviceManagementModules.length > 0) {
        modules["Device Management"] = deviceManagementModules;
      }

      if (securityServicesModules.length > 0) {
        modules["Security Services"] = securityServicesModules;
      }

      return modules;
    });

export default getNavigationModules;
