export enum SmsMessageType {
  NONE = "",
  MESSAGE_SIGNUP = "MESSAGE_SIGNUP",
  MESSAGE_SIGNUP_CONFIRMATION = "MESSAGE_SIGNUP_CONFIRMATION",
  MESSAGE_RESEND_CODE = "MESSAGE_RESEND_CODE",
  MESSAGE_FORGET_PASSWORD = "MESSAGE_FORGET_PASSWORD",
  MESSAGE_ACCOUNT_CLOSED = "MESSAGE_ACCOUNT_CLOSED",
  MESSAGE_ACCOUNT_LOCKED = "MESSAGE_ACCOUNT_LOCKED",
  MESSAGE_ACCOUNT_UNLOCKED = "MESSAGE_ACCOUNT_UNLOCKED",
  MESSAGE_USER_ALREADY_CONFIRMED = "MESSAGE_USER_ALREADY_CONFIRMED",
  MESSAGE_CUSTOM_TEMPLATE = "MESSAGE_CUSTOM_TEMPLATE",
}

export const mapSmsMessageTypeToOptionName = (value: SmsMessageType) => {
  switch (value) {
    case SmsMessageType.MESSAGE_SIGNUP:
      return "New User - Signify Identity - Initial Message";
    case SmsMessageType.MESSAGE_SIGNUP_CONFIRMATION:
      return "New User - Account Terms & Conditions Confirmed";
    case SmsMessageType.MESSAGE_RESEND_CODE:
      return "New User - Signify Identity - Retry Message";
    case SmsMessageType.MESSAGE_FORGET_PASSWORD:
      return "Existing User - Password Recovery Message";
    case SmsMessageType.MESSAGE_ACCOUNT_CLOSED:
      return "Existing User - Close Account Confirmation Message";
    case SmsMessageType.MESSAGE_ACCOUNT_LOCKED:
      return "Existing User - Account Locked Message";
    case SmsMessageType.MESSAGE_ACCOUNT_UNLOCKED:
      return "Existing User - Account Unlocked Message";
    case SmsMessageType.MESSAGE_USER_ALREADY_CONFIRMED:
      return "Existing User - Already Confirmed Message";
    case SmsMessageType.MESSAGE_CUSTOM_TEMPLATE:
      return "Custom Message";
    case SmsMessageType.NONE:
      return null;
    default:
      // If a new type was introduced, we do not want to block it from being used,
      // so just render the value as a string until a proper label is introduced.
      return value + "";
  }
};

export default SmsMessageType;
