import { Record } from "immutable";
import { getStringValue } from "@util";
import { EmailTemplateLanguage, SmsBody, SmsBodyAttributes, SmsMessageType, SmsTemplate } from "@data";

export interface CreateSmsTemplateRequestAttributes {
  language: EmailTemplateLanguage;
  messageType: SmsMessageType;
  body: SmsBodyAttributes;
  applicationId?: string;
}

export class CreateSmsTemplateRequest extends Record({
  language: EmailTemplateLanguage.NONE,
  messageType: SmsMessageType.NONE,
  body: {
    description: "",
    textBody: "",
  },
  applicationId: "",
}) implements CreateSmsTemplateRequestAttributes {

  public static EMPTY: CreateSmsTemplateRequest = new CreateSmsTemplateRequest();

  public readonly language: EmailTemplateLanguage;
  public readonly messageType: SmsMessageType;
  public readonly body: SmsBodyAttributes;
  public readonly applicationId: string;

  public static from(smsTemplate: SmsTemplate): CreateSmsTemplateRequest {
    return new CreateSmsTemplateRequest({
      ...smsTemplate.toJS()
    });
  }

  public getLanguage(): string {
    return getStringValue(this.language);
  }

  public getMessageType(): string {
    return getStringValue(this.messageType);
  }

  public getBody(): SmsBody {
    return new SmsBody(this.body);
  }

  public getTextBody(): string {
    return this.getBody().getTextBody();
  }

  public getDescription(): string {
    return this.getBody().getDescription();
  }

  public getApplicationId(): string {
    return getStringValue(this.applicationId);
  }
}

export default CreateSmsTemplateRequest;
