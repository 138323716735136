import React from "react";
import { CodeInfoView, Model, Actions } from "../components/CodeInfoView";
import {
  useUploadWorkloadCode,
  useGetWorkloadCode,
  useGetUploadWorkloadCodeUrl,
} from "@hooks/workloadsManager";
import { isEmptyString, noop } from "@util";
import {
  DEFAULT_WORKLOAD_RUNTIME_OPTION_LABELS,
  WorkloadRuntimeOption,
  WorkloadRuntimeOptionLabels,
} from "@data/CreateWorkloadRequest";
import { WorkloadCodePackagingStatus, SummaryViewData } from "@data";

interface ContainerModel extends Model {
  workloadName?: string;
  version?: number;
  isDraftWorkload?: boolean;
  fileType?: string;
  entryPoint?: string;
  runtime?: string;
  eTag?: string;
  lastModified?: string;
  contentLength?: string;
  originalFilename?: string;
  showLoadingIndicatorHead?: boolean;
  codeUploaded?: boolean;
  codeUploadDisabled?: boolean;
  codePackingStatus?: WorkloadCodePackagingStatus;
  codePackagingError?: string;
  runtimeOptionLabels?: WorkloadRuntimeOptionLabels;
}

interface ContainerActions extends Actions {
  refresh?: () => void;
  editEntryPoint?: () => void;
  mapRuntimeOptionToLabel?: (type: WorkloadRuntimeOption) => React.ReactNode | string | null;
}

type Props = ContainerModel & ContainerActions;

const CodeInfoViewContainer = (props: Props) => {

  const {
    workloadName = "",
    version = -1,
    isDraftWorkload = false,
    entryPoint = "",
    runtime = "",
    eTag = "",
    lastModified = "",
    contentLength = "",
    fileType = "jar",
    originalFilename = "",
    codePackingStatus = WorkloadCodePackagingStatus.IN_PROGRESS,
    codePackagingError,
    showLoadingIndicatorHead,
    codeUploaded,
    codeUploadDisabled,
    editEntryPoint = noop,
    refresh = noop,
    runtimeOptionLabels = DEFAULT_WORKLOAD_RUNTIME_OPTION_LABELS,
    mapRuntimeOptionToLabel = React.useCallback((type: WorkloadRuntimeOption) =>
      runtimeOptionLabels[type] || null, [ runtimeOptionLabels ]),
  } = props;

  const [ downloading, setDownloading ] = React.useState(false);
  const [ uploading, setUploading ] = React.useState(false);
  const [ codeHref, setCodeHref ] = React.useState("");
  const [ file, setFile ] = React.useState(new File([], ""));
  const [ validationErrorMessage, setValidationErrorMessage ] = React.useState("");
  const [ statusCode, setStatusCode ] = React.useState<number | undefined>();
  const [ saveCodeRequest, setSaveCodeRequest] = React.useState(false);
  const [zipFile, setZipFile] = React.useState<any>(null);

  const fileSelected = React.useMemo(() => file?.size !== 0, [ file ]);
  const fileName = React.useMemo(() => isEmptyString(originalFilename) ? workloadName : originalFilename
    , [ originalFilename, workloadName ]);

  const [ {
    showLoadingIndicator: fileUploadUrlLoading,
    showSuccessView: fileUploadUrlSuccessView,
    showErrorView: fileUploadUrlErrorView,
    errorMessage: uploadUrlErrorMessage,
    statusCode: uploadUrlStatusCode,
    url,
  }, {
    getUploadUrl,
    reset,
  } ] = useGetUploadWorkloadCodeUrl({ workloadName, version, fileName });

  const uploadUrl = React.useMemo(() => url, [ url ]);

  const [ {
    showLoadingIndicator: fileUploadLoading,
    showSuccessView: fileUploadSuccessView,
    showErrorView: fileUploadErrorView,
    errorMessage: uploadErrorMessage,
    statusCode: uploadStatusCode,
  }, {
    uploadCode,
    reset: resetUpload,
  } ] = useUploadWorkloadCode({ uploadUrl, file });

  const [ {
    showLoadingIndicator: fileDownloadLoading,
    url: downloadCodeURL,
    showSuccessView: showDownloadCodeSuccessView,
    showErrorView: showDownloadErrorView,
    errorMessage: downloadErrorMessage,
  }, {
    download,
  } ] = useGetWorkloadCode({ workloadName, version });

  const onSelectFile = React.useCallback(event => {

    reset();
    resetUpload();

    const selectedFile = event && event.target && event.target.files
      ? event.target.files[0] : undefined;

    if (!selectedFile) {
      return setValidationErrorMessage("Invalid File");
    }

    if (!isEmptyString(fileType)) {
      if (selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1) !== fileType) {
        return setValidationErrorMessage(`Invalid File! File format should be .${fileType}`);
      }
    }

    setFile(selectedFile);
    setValidationErrorMessage("");

  }, [ fileType, setFile, reset, setValidationErrorMessage ]);

  const onDrop = React.useCallback(([ selectedFile ]) => {

    reset();
    resetUpload();

    if (!selectedFile) {
      return setValidationErrorMessage("Invalid File");
    }

    if (!isEmptyString(fileType)) {
      if (selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1) !== fileType) {
        return setValidationErrorMessage(`Invalid File! File format should be .${fileType}`);
      }
    }

    setFile(selectedFile);
    setValidationErrorMessage("");

  }, [ fileType, setFile, reset, setValidationErrorMessage ]);

  const fileUploadErrorMessage = React.useMemo(() =>
      uploadUrlErrorMessage || validationErrorMessage || uploadErrorMessage,
    [ uploadUrlErrorMessage, validationErrorMessage, uploadErrorMessage ]);

  const codeInfoSummaryViewItems = React.useMemo(() =>
      showLoadingIndicatorHead ? [] : [
        {
          summaryViewItem:
            new SummaryViewData({
              className: "originalFileName",
              name: "Original File Name",
              value: originalFilename,
            })
        },
        {
          summaryViewItem:
            new SummaryViewData({
              className: "runtime",
              name: "Runtime",
              value: mapRuntimeOptionToLabel(WorkloadRuntimeOption[runtime]),
            })
        }, {
          showEditIcon: isDraftWorkload,
          editTooltipText: "Update Entry Point",
          onClickEditIcon: editEntryPoint,
          summaryViewItem:
            new SummaryViewData({
              className: "entryPoint",
              name: "Entry Point",
              value: entryPoint,
            })
        }, {
          summaryViewItem:
            new SummaryViewData({
              className: "contentLength",
              name: "Length",
              value: contentLength,
            })
        }, {
          summaryViewItem:
            new SummaryViewData({
              className: "lastModified",
              name: "Last Modified",
              value: lastModified,
              date: true,
            })
        },
        {
          summaryViewItem: new SummaryViewData({
            className: "eTag",
            name: "ETag",
            value: eTag,
          })
        },
        {
          summaryViewItem: new SummaryViewData({
            className: "packagingStatus",
            name: "Packaging Status ",
            value: codePackingStatus,
          })
        },
        {
          summaryViewItem: new SummaryViewData({
            className: "packagingStatusError",
            name: "Packaging Status Error",
            value: codePackagingError,
          })
        },
      ],
    [
      showLoadingIndicatorHead,
      originalFilename,
      contentLength,
      lastModified,
      eTag,
      entryPoint,
      runtime,
      isDraftWorkload,
      codePackagingError,
      editEntryPoint,
      mapRuntimeOptionToLabel
    ]);

  const startDownloadCode = React.useCallback(() => {
    setDownloading(true);
    download();
  }, [ setDownloading, download ]);

  const finishDownloadCode = React.useCallback(() => {
    setDownloading(false);
    setCodeHref(downloadCodeURL);
  }, [ setDownloading, setCodeHref, downloadCodeURL ]);

  const startUploadCode = React.useCallback(() => {
    setUploading(true);
    getUploadUrl();
  }, [ setUploading, getUploadUrl ]);

  const isPythonWorkload = React.useMemo(() =>
    runtime === WorkloadRuntimeOption.PYTHON312, [runtime]);

  const pyCodeExists = React.useCallback(() => {
    download();
  }, [download]);

  const enableSaveCodeRequest = React.useCallback(() => {
    setSaveCodeRequest(true);
  }, [setSaveCodeRequest]);

  const disableSaveCodeRequest = React.useCallback(() => {
    setSaveCodeRequest(false);
  }, [setSaveCodeRequest]);

  React.useEffect(() => {
    let isMounted = true;

    if (downloadCodeURL) {
      fetch(downloadCodeURL)
        .then(response => response.blob())
        .then(blob => {
          if (isMounted) {
            const downloadedFile = new File([blob], `${workloadName}.${version}.zip`, { type: "application/zip" });
            setZipFile(downloadedFile);
          }
        })
        .catch(error => {
          if (isMounted) {
            console.error("Error downloading the zip file:", error);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [downloadCodeURL, setZipFile]);

  React.useEffect(() => {
    if (downloading && !fileDownloadLoading && showDownloadCodeSuccessView) {
      finishDownloadCode();
    } else {
      setCodeHref("");
    }
  }, [downloading, fileDownloadLoading, showDownloadCodeSuccessView, finishDownloadCode, setCodeHref]);

  React.useEffect(() => {
    if (uploading && fileUploadUrlSuccessView && !isEmptyString(uploadUrl)) {
      // after getting the upload URL, upload code file
      uploadCode();
    }
  }, [uploading, fileUploadUrlSuccessView, uploadUrl]);

  React.useEffect(() => {
    if (uploading && fileUploadSuccessView) {
      // upload is complete
      setUploading(false);
      // Do not refresh page if code is saved from editor
      if (!saveCodeRequest) {
        refresh();
      }
    }
  }, [uploading, fileUploadSuccessView, setUploading, refresh]);

  React.useEffect(() => {
    if (fileUploadUrlErrorView) {
      setStatusCode(uploadUrlStatusCode);
    }
  }, [fileUploadUrlErrorView, uploadUrlStatusCode, setStatusCode]);

  React.useEffect(() => {
    if (fileUploadErrorView) {
      setStatusCode(uploadStatusCode);
    }
  }, [fileUploadErrorView, uploadStatusCode, setStatusCode]);

  React.useEffect(() => {
    if (codeUploaded && isPythonWorkload) {
      pyCodeExists();
    }
  }, []);

  return (
    <CodeInfoView
      summaryViewItems={codeInfoSummaryViewItems}
      fileDownloadLoading={fileDownloadLoading}
      codeUploaded={codeUploaded}
      href={codeHref}
      pythonWorkload={isPythonWorkload}
      fileUploadErrorMessage={fileUploadErrorMessage}
      showDownloadErrorView={showDownloadErrorView}
      downloadErrorMessage={downloadErrorMessage}
      fileSelected={fileSelected}
      fileName={file.name}
      fileType={fileType}
      zipFile={zipFile}
      entryFileName={entryPoint.split(".")[0] + ".py"}
      entryPoint={entryPoint}
      saveCodeRequest={saveCodeRequest}
      fileUploadLoading={fileUploadUrlLoading || fileUploadLoading}
      fileUploadSuccess={fileUploadUrlSuccessView && fileUploadSuccessView}
      downloadCode={startDownloadCode}
      uploadCode={startUploadCode}
      onSelectFile={onSelectFile}
      onSuccessUploadPyCode={pyCodeExists}
      onDrop={onDrop}
      workloadName={workloadName}
      codeUploadDisabled={codeUploadDisabled}
      statusCode={statusCode}
      setFile={setFile}
      refresh={refresh}
      enableSaveCodeRequest={enableSaveCodeRequest}
      disableSaveCodeRequest={disableSaveCodeRequest}
    />
  );
};

export default CodeInfoViewContainer;
