import React from "react";
import { isEmptyString } from "@util";
import { InviteUserRequest } from "@data";
import { InviteUserResponse } from "@network";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useUserIdmClient } from "@hooks";

type SuccessResponse = InviteUserResponse;

export interface UseInviteUserProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  userId: string;
  applicationId: string;
  templateId: string;
  sendEmailCopy: boolean;
  inviteUserRequest: InviteUserRequest;
}

export interface UseInviteUserModel extends UseApiRequestModel<SuccessResponse> {
  inviteUserResponse: InviteUserResponse;
  successMessage: string;
}

export interface UseInviteUserActions {
  inviteUser: () => void;
  reset: () => void;
}

type Props = UseInviteUserProps;
type Model = UseInviteUserModel;
type Actions = UseInviteUserActions;
type Result = [Model, Actions];

export const useInviteUser = (props: Props): Result => {

  const {
    userId = "",
    applicationId = "",
    templateId = "",
    sendEmailCopy = false,
    inviteUserRequest = InviteUserRequest.EMPTY,
    defaultErrorMessage = "Failed to invite user",
    ...otherProps
  } = props;

  const UserIdmClient = useUserIdmClient();

  const makeApiRequest = React.useCallback(() =>
    UserIdmClient.inviteUser(applicationId, JSON.stringify({
      userId,
      copyEmailToAdmin: sendEmailCopy,
      profile: inviteUserRequest.profile,
      ...(isEmptyString(templateId) ? ({}) : ({
        templateId,
        // TODO: Remove as part of IOTSST-2956 when the invite user API is updated to use just templateId
        //       instead of the current implementation that uses emailTemplateId. We can ensure compatibility
        //       for now by sending both, but eventually we should only be sending templateId.
        emailTemplateId: templateId,
      })),
    })), [UserIdmClient, applicationId, userId, sendEmailCopy, inviteUserRequest, templateId]);

  const [{ successResponse, showSuccessView, ...baseModel }, { reset, refresh: inviteUser }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const inviteUserResponse = React.useMemo<InviteUserResponse>(() => {
    const { userId: actualUserId = "", anonymousId = "", updatedAt = "" } = successResponse || {};
    return {
      updatedAt,
      anonymousId,
      userId: actualUserId,
    };
  }, [successResponse]);

  const successMessage = React.useMemo(() => showSuccessView ?
    "User Invited" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    successResponse,
    showSuccessView,
    inviteUserResponse,
  }), [
    baseModel,
    successMessage,
    successResponse,
    showSuccessView,
    inviteUserResponse,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    reset,
    inviteUser,
  }), [
    reset,
    inviteUser,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useInviteUser;
