import React from "react";
import { noop } from "@util";
import SmsTemplate from "@data/SmsTemplate";
import { useGetSmsTemplates } from "@hooks";
import { PrimaryIcon } from "@modules/smsTemplates/styles";
import { getPathToSmsTemplate } from "@modules/smsTemplateDetails/helpers";
import ModuleListView, {
  createColumns,
  ListViewItem,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";

export enum SmsTemplatesListColumn {
  ID = "Template Id",
  APPLICATION_ID = "Application Id",
  LANGUAGE = "Language",
  TYPE = "Type",
  DESCRIPTION = "Description",
}

type Item = SmsTemplate;
type Column = SmsTemplatesListColumn;

const DEFAULT_COLUMNS: Column[] = [
  SmsTemplatesListColumn.ID,
  SmsTemplatesListColumn.APPLICATION_ID,
  SmsTemplatesListColumn.LANGUAGE,
  SmsTemplatesListColumn.TYPE,
  SmsTemplatesListColumn.DESCRIPTION,
];

export interface SmsTemplatesListModel extends ModuleListViewModel<Item, Column> {
}

export interface SmsTemplatesListActions extends ModuleListViewActions<Item, Column> {
  showSmsTemplateDetails?: (templateId: string) => void;
}

type Model = SmsTemplatesListModel;
type Actions = SmsTemplatesListActions;
type Props = Model & Actions;

export const SmsTemplatesList = (props: Props) => {

  const {
    className = "smsTemplates",
    listClassName = "smsTemplatesList",
    columns = DEFAULT_COLUMNS,
    summaryViewIcon = PrimaryIcon,
    summaryViewLabel = "SMS Templates",
    showSummary = true,
    showSearch = true,
    noResultsLabel = "No SMS templates found",
    summaryViewQuantities = {
      other: "SMS templates",
      one: "SMS template",
    },
    showSmsTemplateDetails = noop,
    onClickItem = React.useCallback((item: Item) =>
        showSmsTemplateDetails(item.getId()),
      [showSmsTemplateDetails]),
    ...otherProps
  } = props;

  const [{ smsTemplates, ...model }, actions] = useGetSmsTemplates();

  const moduleListItems: ListViewItem<SmsTemplate>[] = React.useMemo(() =>
    smsTemplates.map(template => {
      return {
        item: template,
        icon: PrimaryIcon,
        pathToDetailsView: getPathToSmsTemplate(template.getId()),
        columnAttributes: createColumns([
          {
            className: "templateId",
            value: template.getId(),
            column: SmsTemplatesListColumn.ID,
            firstColumn: true,
          },
          {
            className: "templateApplicationId",
            value: template.getApplicationId(),
            column: SmsTemplatesListColumn.APPLICATION_ID,
          },
          {
            className: "templateLanguage",
            value: template.getLanguage(),
            column: SmsTemplatesListColumn.LANGUAGE,
          },
          {
            className: "templateType",
            value: template.getMessageType(),
            column: SmsTemplatesListColumn.TYPE,
          },
          {
            className: "templateDescription",
            value: template.getDescription(),
            column: SmsTemplatesListColumn.DESCRIPTION,
          },
        ]),
      };
    }), [smsTemplates]);

  return (
    <ModuleListView
      {...otherProps}
      {...model}
      {...actions}
      className={className}
      listClassName={listClassName}
      columns={columns}
      showSummary={showSummary}
      showSearch={showSearch}
      summaryViewIcon={summaryViewIcon}
      summaryViewLabel={summaryViewLabel}
      listViewItems={moduleListItems}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
      onClickItem={onClickItem}
    />
  );
};

export default SmsTemplatesList;
