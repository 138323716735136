import { Record } from "immutable";
import UserIdentityType from "@data/UserIdentityType";

export interface InviteUserStateAttributes {
  userId: string;
  identityType: UserIdentityType;
  applicationId: string;
  emailTemplateId: string;
  smsTemplateId: string;
  profileValueUpdated: boolean;
}

export class InviteUserState extends Record({
  userId: "",
  identityType: UserIdentityType.EMAIL,
  applicationId: "",
  emailTemplateId: "",
  smsTemplateId: "",
  profileValueUpdated: false,
}) implements InviteUserStateAttributes {

  public static EMPTY = new InviteUserState();

  public readonly userId: string;
  public readonly identityType: UserIdentityType;
  public readonly applicationId: string;
  public readonly emailTemplateId: string;
  public readonly smsTemplateId: string;
  public readonly profileValueUpdated: boolean;
}

export default InviteUserState;
