import Color from "@Color";
import ModuleIcon from "@material-ui/icons/MonetizationOn";
import createStyles from "@material-ui/core/styles/createStyles";

export const PrimaryIcon = ModuleIcon;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    header: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    icon: {
      color: Color.SIGNIFY_GREEN,
      width: "auto",
      height: 48,
    },
    title: {
      marginLeft: 10,
      color: Color.TEXT,
      fontWeight: 300,
    },
    chart: {
      marginTop: 10,
      flex: "1 0 auto",
      minWidth: 400,
      minHeight: 400,
    },
    fullContainer: {
      width: "100%",
    },
    leftContainer: {
      width: "78%",
    },
    rightContainer: {
      minHeight: 500,
      width: "18%",
      marginLeft: "2%",
    },
    infoLabel: {
      fontSize: 14,
      color: Color.GREY3,
      lineHeight: "36px",
    },
    infoLabelContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    filterButton: {
      "& .MuiButton-label": {
        color: Color.GREY3,
      },
      boxShadow: "0 2px 4px 2px rgba(0, 0, 0, 0.1)",
    },
    headers: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      justifyContent: "space-around",
      gap: 10,
      marginBottom: 10,
    },
    headerCard: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px 20px",
      margin: "10px 0px",
      color: Color.TEXT,
      fontSize: 16,
      fontWeight: 300,
      width: "fit-content",
      minWidth: "19%",
      minHeight: 100,
      borderRadius: 4,
      boxShadow: "0 2px 4px 2px rgba(0, 0, 0, 0.1)",
    },
    headerCardTitle: {
      color: Color.TEXT,
      fontSize: 16,
      fontWeight: 500,
    },
    headerCardSubTitle: {
      color: Color.SIGNIFY_GREEN2,
      fontSize: 24,
      fontWeight: 300,
      marginTop: 10,
    },
    headerFilterCard: {
      marginRight: 0,
      width: "100%",
      justifyContent: "normal",
    },
    headerFilterCardTitle: {
      color: Color.TEXT,
      fontSize: 20,
      fontWeight: 500,
      marginTop: 30,
      marginBottom: 30,
    },
    dropdown: {
      alignItems: "stretch",
      marginBottom: 25,
      width: "100%",
      "& .MuiOutlinedInput-input": {
        paddingBottom: 12,
        paddingLeft: 10,
      },
      "& .dropdownMenuHelperText": {
        marginLeft: 4,
      },
    },
    dropdownMenuEmptyValueLabel: {
      color: Color.GREY3,
      fontStyle: "normal",
    },
    dropdownMenuSelect: {
      "& > fieldset": {
        zIndex: 0,
        top: 0,
      },
    },
    dropdownMenuLabel: {
      zIndex: 1,
      backgroundColor: Color.WHITE,
      padding: "0 4px",
      marginLeft: 6,
      fontSize: 16,
      lineHeight: "16px",
    },
    graphUnit: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 25,
      width: "100%",
      "& .MuiToggleButtonGroup-root": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: 10,
      },
    }
  });

export default styles;
