import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {  styles } from "./styles";
import LoadingIndicator from "@components/user-profile-attributes-list/LoadingIndicator";
import { checkboxEventHandler, isEmptyString, noop } from "@util";
import { ConfirmationDialog } from "@components";
import { useDropzone } from "react-dropzone";
import Checkbox from "@material-ui/core/Checkbox";

export const UploadDialog = withStyles(styles)((props: WithStyles<typeof styles> & {
  open?: boolean;
  title?: string;
  file?: File;
  fileType?: string;
  uploading?: boolean
  successDialog?: boolean;
  showCloseIconOnSuccess?: boolean;
  successMessage?: string;
  errorMessage?: string;
  warningMessage?: string;
  fileLocationUrl?: string;
  baseUrl?: string;
  statusCode?: number;
  cancel?: () => void;
  setUploading?: (value: boolean) => void;
  setFile?: (file: File) => void;
  setErrorMessage?: (error: string) => void;
}) => {

  const {
    classes,
    open = true,
    title = "Upload",
    file,
    fileType = "",
    successDialog = false,
    uploading = false,
    showCloseIconOnSuccess = false,
    successMessage = "",
    errorMessage = "",
    warningMessage = "",
    fileLocationUrl = "",
    baseUrl = "",
    statusCode,
    cancel = noop,
    setUploading = noop,
    setFile = noop,
    setErrorMessage = noop,
  } = props;

  const [checked, setChecked] = React.useState(false);

  const onSelectFile = React.useCallback(event => {

    const selectedFile = event && event.target && event.target.files
      ? event.target.files[0] : undefined;

    if (!selectedFile) {
      return setErrorMessage("Invalid File");
    }

    if (!isEmptyString(fileType)) {
      if (selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1) !== fileType) {
        return setErrorMessage(`Invalid File! File format should be .${fileType}`);
      }
    }

    setFile(selectedFile);
    setErrorMessage("");

  }, [fileType, setFile, setErrorMessage]);

  const onDrop = React.useCallback(([selectedFile]) => {

    if (!selectedFile) {
      return setErrorMessage("Invalid File");
    }

    if (!isEmptyString(fileType)) {
      if (selectedFile.name.substring(selectedFile.name.lastIndexOf(".") + 1) !== fileType) {
        return setErrorMessage(`Invalid File! File format should be .${fileType}`);
      }
    }

    setFile(selectedFile);
    setErrorMessage("");

  }, [fileType, setFile, setErrorMessage]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  const showWarningMessage = React.useMemo(() =>
    !isEmptyString(warningMessage), [warningMessage]);

  const continueButtonDisabled = React.useMemo(() =>
    !file || !isEmptyString(errorMessage) || (showWarningMessage && !checked),
    [file, errorMessage, checked, showWarningMessage]);

  return (
    <ConfirmationDialog
      className={classnames("uploadDialog", classes.dialog)}
      open={open}
      title={title}
      continueButtonLabel="Upload"
      continueButtonDisabled={continueButtonDisabled}
      cancelButtonDisabled={uploading || !isEmptyString(successMessage)}
      loading={uploading}
      errorMessage={errorMessage}
      successMessage={successMessage}
      confirm={() => setUploading(true)}
      cancel={cancel}
      showCloseIconOnSuccess={showCloseIconOnSuccess}
      onClickCloseIcon={cancel}
      statusCode={statusCode}
    >
    {showWarningMessage && (
      <div className={classnames("warningContainer", classes.warningContainer)}>
        <label className={classnames("warningMessage", classes.information)}>
          <span className={classes.warning}>WARNING:</span>{warningMessage}!
        </label>
      </div>
    )}
      <React.Fragment>
        {!successDialog && (
          <form
            {...getRootProps()}
            className={classnames("uploadDialogContent", classes.dialogContent, {
              [classes.dialogContentDragging]: isDragActive,
              [classes.dialogContentSelected]: file && !isDragActive,
            })}
          >
            {!file && !successDialog && (
              <label className={classes.dialogLabel}>Select a file to upload</label>
            )}
            {file && !uploading && !successDialog && (
              <label className={classes.dialogLabel}>
                Click upload to proceed <br/> Click here to upload a different file
              </label>
            )}
            {file && uploading && !successDialog && (
              <LoadingIndicator
                className={classnames("loadingIndicator", classes.loadingIndicator)}
                label="Uploading..."
                labelClassName={classnames("uploading", classes.dialogLabel)}
              />
            )}
            {!uploading && !successMessage && (
              <input {...getInputProps()} type="file" onChange={onSelectFile}/>
            )}
          </form>
        )}
        {successDialog && (
          <React.Fragment>
            <label className={classnames("subtitle", classes.subtitle)}>
              File Location URL :
            </label>
            <a
              className={classnames("subtitle", classes.subtitle)}
              href={fileLocationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {fileLocationUrl}
            </a>
            <br/><br/>
            <label className={classnames("subtitle", classes.subtitle)}>
              Base URL :
            </label>
            <a
              className={classnames("subtitle", classes.subtitle)}
              href={baseUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {baseUrl}
            </a>
          </React.Fragment>
        )}
      </React.Fragment>
      {showWarningMessage && (
        <div className={classnames("checkboxContainer", classes.checkboxContainer)}>
          <Checkbox
            className={classnames("checkbox", classes.checkbox)}
            checked={checked}
            onChange={checkboxEventHandler(setChecked)}
          />
          <label className={classnames("checkboxMessage", classes.checkboxMessage)}>
            I understand this action will replace the existing code
          </label>
        </div>
      )}
    </ConfirmationDialog>
  );
});

export default UploadDialog;
