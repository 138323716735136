import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { CostReportingMetric } from "@data";
import ModuleListView from "@components/module-list-view";
import { formatMetricDate } from "@modules/costReports/helpers";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "@components/sorted-search-results-list";

export enum CostReportingListColumn {
  NONE = "",
  DATE = "Date (UTC)",
  DOMAIN = "Domain",
  GROUP = "Group",
  CATEGORY = "Category",
  METRIC = "Metric",
  RESOURCE = "Resource",
  COUNT = "Count",
  COST = "Cost",
}

export const DEFAULT_COST_REPORTING_LIST_COLUMNS: CostReportingListColumn[] = [
  CostReportingListColumn.DATE,
  CostReportingListColumn.DOMAIN,
  CostReportingListColumn.GROUP,
  CostReportingListColumn.CATEGORY,
  CostReportingListColumn.METRIC,
  CostReportingListColumn.RESOURCE,
  CostReportingListColumn.COUNT,
  CostReportingListColumn.COST,
];

type Item = CostReportingMetric;
type Column = CostReportingListColumn;

export interface CostReportingListModel extends SortedSearchResultsListModel<Item, Column> {
  metrics?: CostReportingMetric[];
}

export interface CostReportingListActions extends SortedSearchResultsListActions<Item, Column> {
}

type Model = CostReportingListModel;
type Actions = CostReportingListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CostReportingList = withStyles(styles)((props: Props) => {

  const {
    classes,
    metrics = [],
    className = "costReportingList",
    columns = DEFAULT_COST_REPORTING_LIST_COLUMNS,
    noResultsLabel = "No cost reporting metrics found for this account",
    summaryViewQuantities = {
      other: "metrics",
      one: "metric",
    },
    ...otherProps
  } = props;

  const moduleListItems: ListViewItem<CostReportingMetric>[] = React.useMemo(() =>
    metrics.map(metric => {
      return {
        item: metric,
        pathToDetailsView: "",
        icon: null,
        columnAttributes: createColumns([
          {
            className: "metricDate",
            value: formatMetricDate(metric.getPeriodType(), metric.getDate()),
            column: CostReportingListColumn.DATE,
            firstColumn: true,
          },
          {
            className: "metricDomain",
            value: metric.getDomain(),
            column: CostReportingListColumn.DOMAIN,
          },
          {
            className: "metricGroup",
            value: metric.getGroup(),
            column: CostReportingListColumn.GROUP,
          },
          {
            className: "metricCategory",
            value: metric.getCategory(),
            column: CostReportingListColumn.CATEGORY,
          },
          {
            className: "metricType",
            value: metric.getMetricType(),
            column: CostReportingListColumn.METRIC,
          },
          {
            className: "metricResource",
            value: metric.getResourceType(),
            column: CostReportingListColumn.RESOURCE,
          },
          {
            className: "metricCount",
            value: metric.getCountValue(),
            column: CostReportingListColumn.COUNT,
          },
          {
            className: "metricCost",
            value: metric.getCostValue(),
            column: CostReportingListColumn.COST,
          },
        ])
      };
    }), [metrics]);

  return (
    <ModuleListView
      {...otherProps}
      className={classnames(className, classes.container)}
      columns={columns}
      fixedRowHeight={false}
      tableLayoutFixed={false}
      listViewItems={moduleListItems}
      noResultsLabel={noResultsLabel}
      summaryViewQuantities={summaryViewQuantities}
    />
  );
});

export default CostReportingList;
